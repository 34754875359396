import React, { FC, useEffect, useState, useMemo } from 'react';
import { Modal, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import type { DimensionValue } from 'react-native';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { RootState } from 'store';
import { getUnavailablePDFReportProducts } from 'store/upsails/selectors';
import { isSolidgatePaymentService } from 'store/billing/selectors';
import Button from 'components/button';
import Iframe from 'components/web/iframe';
import { WEB_POST_MESSAGE_STATUS } from 'constants/billing';

interface Props {
  buttonTitle: string;
  style?: StyleProp<ViewStyle>;
  onSuccess: (data: any) => void;
  onSkip: () => void;
  onPress: () => void;
}

const PurchasePDFReportsButton: FC<Props> = ({ buttonTitle, style, onSuccess, onSkip, onPress }) => {
  const idfm = useSelector((state: RootState) => state.auth.webUUID);
  const unavailableReports = useSelector(getUnavailablePDFReportProducts);
  const isSolidgate = useSelector((state: RootState) => isSolidgatePaymentService(state));
  const webMode = useSelector((state: RootState) => state.app.webMode);

  const [isIframeOpen, setIsIframeOpen] = useState(false);

  const url = useMemo(() => {
    const query: any = {
      idfm,
      skipOneClick: !isSolidgate ? null : undefined,
      mode: webMode,
    };

    if (unavailableReports.length === 1) {
      query.product = unavailableReports[0];
    }

    return queryString.stringifyUrl({
      url: process.env.REACT_APP_WEB_REPORTS_URL as unknown as string,
      query,
    });
  }, [idfm, unavailableReports, isSolidgate]);

  const handlePress = () => {
    setIsIframeOpen(true);
    onPress();
  };

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event?.data?.source === 'pdf-reports') {
        const status = event?.data?.status as WEB_POST_MESSAGE_STATUS;

        switch (status) {
          case WEB_POST_MESSAGE_STATUS.SUCCESS:
            if (typeof onSuccess === 'function') {
              onSuccess(event?.data);
            }
            break;
          case WEB_POST_MESSAGE_STATUS.SKIPED:
            if (typeof onSkip === 'function') {
              onSkip();
            }
            break;
          default:
            break;
        }

        if (status !== WEB_POST_MESSAGE_STATUS.LOADED) {
          setIsIframeOpen(false);
        }
      }
    };
    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onSkip, onSuccess]);

  const closeModal = () => setIsIframeOpen(false);

  return (
    <>
      <Button title={buttonTitle} style={style} onPress={handlePress} />
      <Modal visible={isIframeOpen} style={styles.iframe_modal}>
        <Iframe id="pdfReportsIframe" src={url} onError={closeModal} />
      </Modal>
    </>
  );
};

export default PurchasePDFReportsButton;

const styles = StyleSheet.create({
  iframe_modal: {
    width: '100vw' as DimensionValue,
    height: '100vh' as DimensionValue,
    top: 0,
    left: 0,
  },
});
