import api from '..';

import { AspectDescription, TransitDescription } from './interfaces';

const TRANSITS = '/transits';
const ASPECTS = '/birthchart/aspects';

export const getTransitsContent = (): Promise<TransitDescription[]> => {
  return api.fetch(TRANSITS, {
    method: 'GET',
  });
};

export const getAspectsContent = (): Promise<AspectDescription[]> => {
  return api.fetch(ASPECTS, {
    method: 'GET',
  });
};
