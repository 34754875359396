import React, { FC, useEffect } from 'react';
import { StyleSheet, View, StatusBar, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import ChangeIconPremiumModal from 'reusable/change-icon-premium-modal';
import NoInternetModal from 'reusable/no-internet-modal';
import LoaderOverlay from 'components/loader/overlay';
import { BLACK_OPACITY } from 'constants/colors';
import { AppDispatch, RootState } from 'store';
import BackgroundPlacement from 'components/background-placement';
import WebNotificationRequestOverlay from 'components/web-notification-request-overlay';
import NotificationHandler from 'components/notification-handler';
import RateUsModal from 'components/rate-us/modal';
import { onMountApp } from 'store/app/actions';
import StaticHooksRunner from 'hooks/static-hooks-runner';
import useBackHandler from 'hooks/use-back-handler';
import useChatAnswers from 'hooks/use-chat-answers';
import useChatTimeBalance from 'hooks/use-chat-time-balance';
import { isStandalone } from 'utils/pwa';

import FamilyGuide from './modal/family/guide';
import FamilyWelcome from './modal/family/welcome';
import FeedbackModal from './modal/feedback';
import Navigator from './navigator';
import OfferWelcome from './modal/family/offer';
import PersonalReportsModal from './modal/personal-reports';
import WebReactivationModal from './modal/web-reactivation';
import WebGetPDFGuideModal from './modal/web-get-pdf-reports';
import ReactivationThankYouModal from './modal/web-reactivation/thank-you';
import WebBirthChartModal from './modal/web-birth-chart';
import WebFreeConsultationModal from './modal/web-free-consultation';
import PWAInstructionsModal from './web/pwa-instructions';
import AdvisorAstroevent from './modal/advisor-astroevent';
import MessengersModal from './modal/messengers';
import WebAstrologersOneTimeModal from './modal/web-astrologers-one-time';
import InstallMobileApp from './modal/install-mobile-app';
import AdvisorServiceInfoModal from './modal/advisor-service-info';
import SubBenefitsModal from './modal/sub-benefits';
import AstroCalendarNotification from './astro-calendar/components/astro-calendar-notification';
import TrustPilotPromoModal from './modal/trust-pilot-promo';

const App: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isOverlayLoaderActive = useSelector((state: RootState) => state.app.isOverlayLoaderActive);
  const feedbackUrl = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.feedbackUrl);

  useBackHandler();
  useChatAnswers();
  useChatTimeBalance();

  useEffect(() => {
    dispatch(onMountApp());
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar barStyle="light-content" translucent backgroundColor="transparent" animated />
      <Navigator />
      <NoInternetModal />
      <RateUsModal />
      {feedbackUrl ? <FeedbackModal /> : null}
      <ChangeIconPremiumModal />
      <LoaderOverlay visible={isOverlayLoaderActive} style={{ backgroundColor: BLACK_OPACITY }} />
      <FamilyGuide />
      <FamilyWelcome />
      <OfferWelcome />
      <AdvisorAstroevent />
      <MessengersModal />
      <AstroCalendarNotification />
      <SubBenefitsModal />
      <PersonalReportsModal />
      {Platform.OS === 'web' && (
        <>
          <WebReactivationModal />
          <ReactivationThankYouModal />
          <WebGetPDFGuideModal />
          <WebBirthChartModal />
          <PWAInstructionsModal />
          <WebFreeConsultationModal />
          <WebAstrologersOneTimeModal />
          {!isStandalone() && <InstallMobileApp />}
          <TrustPilotPromoModal />
        </>
      )}
      <AdvisorServiceInfoModal />
      <BackgroundPlacement />
      <NotificationHandler />
      <WebNotificationRequestOverlay />
      <StaticHooksRunner />
    </View>
  );
};

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
