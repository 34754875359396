import { MonetizationConfig, MONETIZATION_TYPES } from 'constants/monetization-types';
import * as TRIGGERS from 'constants/monetization-triggers';
import * as SUB_SCREENS from 'constants/subscription-screens';

export const monetizationConfigIos: MonetizationConfig = {
  [`${TRIGGERS.APP_FOREGROUND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.TABS}`]: [],
  [`${TRIGGERS.NATIVE_WIDGET}`]: [],
  [`${TRIGGERS.DIAMOND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.SETTINGS_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.PERSONAL_READING_OPEN}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.TOMORROW]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.WEEKLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.MONTHLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.YEARLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_SWITCH}`]: [],
  [`${TRIGGERS.PALM_READING_OPEN}`]: [],
  [`${TRIGGERS.PALM_READING_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.PALM_READING_CLOSE}`]: [],
  [`${TRIGGERS.STORIES_OPEN}`]: [],
  [`${TRIGGERS.STORIES_NEXT_CATEGORY}`]: [],
  [`${TRIGGERS.STORIES_CLOSE}`]: [],
  [`${TRIGGERS.BIORHYTHM_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BIORHYTHM_TAB}`]: [],
  [`${TRIGGERS.BIORHYTHM_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BIORHYTHM_CLOSE}`]: [],
  [`${TRIGGERS.LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.LUNAR_TAB}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.FEATURES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.FEATURES_CLOSE}`]: [],
  [`${TRIGGERS.DATING_CALENDAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.DATING_CALENDAR_TAB}`]: [],
  [`${TRIGGERS.DATING_CALENDAR_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.DATING_CALENDAR_CLOSE}`]: [],
  [`${TRIGGERS.MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_CLOSE}`]: [],
  [`${TRIGGERS.TAROT_OPEN}`]: [],
  [`${TRIGGERS.TAROT_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.TAROT_EXPAND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.MAGIC_BALL_OPEN}`]: [],
  [`${TRIGGERS.MAGIC_BALL_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.MAGIC_BALL_CLOSE}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_CHECK}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_REPORT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS}`]: [],
  [`${TRIGGERS.BEST_MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_OPEN}`]: [],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_CLOSE}`]: [],
  [`${TRIGGERS.BEST_MATCHES_EXPAND}`]: [],
  [`${TRIGGERS.BEST_MATCHES_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BEST_MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_OPEN}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.GUIDE_SESSION_START_NEXT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.GUIDE_SESSION_BACK_TO_GUIDES}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SKIP}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SAVE_A_NOTE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_MODAL_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_COMPLETE}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.GUIDE_SESSION_UNLOCK_ALL}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.GUIDE_SESSION_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_TAB}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_CLOSE}`]: [],
};

export const monetizationConfigAndroid: MonetizationConfig = {
  [`${TRIGGERS.APP_FOREGROUND}`]: [],
  [`${TRIGGERS.TABS}`]: [],
  [`${TRIGGERS.DIAMOND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.SETTINGS_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.PERSONAL_READING_OPEN}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.TOMORROW]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.WEEKLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.MONTHLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.YEARLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_SWITCH}`]: [],
  [`${TRIGGERS.PALM_READING_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.PALM }],
  [`${TRIGGERS.PALM_READING_GET_RESULT}`]: [],
  [`${TRIGGERS.PALM_READING_CLOSE}`]: [],
  [`${TRIGGERS.STORIES_OPEN}`]: [],
  [`${TRIGGERS.STORIES_NEXT_CATEGORY}`]: [],
  [`${TRIGGERS.STORIES_CLOSE}`]: [],
  [`${TRIGGERS.BIORHYTHM_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BIORHYTHM_TAB}`]: [],
  [`${TRIGGERS.BIORHYTHM_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BIORHYTHM_CLOSE}`]: [],
  [`${TRIGGERS.LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.LUNAR_TAB}`]: [],
  [`${TRIGGERS.LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.FEATURES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.FEATURES_CLOSE}`]: [],
  [`${TRIGGERS.DATING_CALENDAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.DATING_CALENDAR_TAB}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.DATING_CALENDAR_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.DATING_CALENDAR_CLOSE}`]: [],
  [`${TRIGGERS.MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_CLOSE}`]: [],
  [`${TRIGGERS.TAROT_OPEN}`]: [],
  [`${TRIGGERS.TAROT_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.TAROT_EXPAND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.MAGIC_BALL_OPEN}`]: [],
  [`${TRIGGERS.MAGIC_BALL_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.MAGIC_BALL_CLOSE}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_CHECK}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_REPORT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS}`]: [],
  [`${TRIGGERS.BEST_MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_OPEN}`]: [],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_CLOSE}`]: [],
  [`${TRIGGERS.BEST_MATCHES_EXPAND}`]: [],
  [`${TRIGGERS.BEST_MATCHES_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BEST_MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_OPEN}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.GUIDE_SESSION_START_NEXT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.GUIDE_SESSION_BACK_TO_GUIDES}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SKIP}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SAVE_A_NOTE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_MODAL_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_COMPLETE}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.GUIDE_SESSION_UNLOCK_ALL}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.GUIDE_SESSION_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_OPEN}`]: [],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.VERTICAL_ONE }],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_TAB}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_CLOSE}`]: [],
};

export const monetizationConfigWeb: MonetizationConfig = {
  [`${TRIGGERS.APP_FOREGROUND}`]: [],
  [`${TRIGGERS.TABS}`]: [],
  [`${TRIGGERS.WHERE_TO_NAVIGATE}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.DIAMOND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.SETTINGS_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.PERSONAL_READING_OPEN}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.TOMORROW]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.WEEKLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.MONTHLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_TABS[TRIGGERS.TABS_TYPES.YEARLY]}`]: [],
  [`${TRIGGERS.PERSONAL_READING_SWITCH}`]: [],
  [`${TRIGGERS.PALM_READING_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.PALM }],
  [`${TRIGGERS.PALM_READING_GET_RESULT}`]: [],
  [`${TRIGGERS.PALM_READING_CLOSE}`]: [],
  [`${TRIGGERS.STORIES_OPEN}`]: [],
  [`${TRIGGERS.STORIES_NEXT_CATEGORY}`]: [],
  [`${TRIGGERS.STORIES_CLOSE}`]: [],
  [`${TRIGGERS.BIORHYTHM_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BIORHYTHM_TAB}`]: [],
  [`${TRIGGERS.BIORHYTHM_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BIORHYTHM_CLOSE}`]: [],
  [`${TRIGGERS.LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.LUNAR_TAB}`]: [],
  [`${TRIGGERS.LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.FEATURES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.FEATURES_CLOSE}`]: [],
  [`${TRIGGERS.DATING_CALENDAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.DATING_CALENDAR_TAB}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.DATING_CALENDAR_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.DATING_CALENDAR_CLOSE}`]: [],
  [`${TRIGGERS.MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_TAB}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_INDIAN_SOLAR_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_DRUID_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_MAYAN_CLOSE}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_SIGN}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_EXPAND}`]: [],
  [`${TRIGGERS.ALT_HOROSCOPES_CHINESE_CLOSE}`]: [],
  [`${TRIGGERS.TAROT_OPEN}`]: [],
  [`${TRIGGERS.TAROT_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.TAROT_EXPAND}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.MAGIC_BALL_OPEN}`]: [],
  [`${TRIGGERS.MAGIC_BALL_GET_RESULT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.MAGIC_BALL_CLOSE}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_CHECK}`]: [],
  [`${TRIGGERS.SIGNS_COMPATIBILITY_REPORT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.FAMILY }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS}`]: [],
  [`${TRIGGERS.BEST_MATCHES_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_OPEN}`]: [],
  [`${TRIGGERS.BEST_MATCHES_ALL_SIGNS_CLOSE}`]: [],
  [`${TRIGGERS.BEST_MATCHES_EXPAND}`]: [],
  [`${TRIGGERS.BEST_MATCHES_BANNER}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BEST_MATCHES_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_OPEN}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_OPEN}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.GUIDE_SESSION_START_NEXT}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.GUIDE_SESSION_BACK_TO_GUIDES}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SKIP}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_SAVE_A_NOTE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_MODAL_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_SESSION_COMPLETE}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.GUIDE_SESSION_UNLOCK_ALL}`]: [{ type: MONETIZATION_TYPES.SUBSCRIPTION, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.GUIDE_SESSION_CLOSE}`]: [],
  [`${TRIGGERS.GUIDE_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_TAB}`]: [],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_CLOSE}`]: [],
  [`${TRIGGERS.BIRTH_CHART_OPEN}`]: [{ type: MONETIZATION_TYPES.IN_APP, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BIRTH_CHART_YOUR_CHART_OPEN}`]: [{ type: MONETIZATION_TYPES.IN_APP, screen: SUB_SCREENS.WEB }],
  [`${TRIGGERS.BIRTH_CHART_DAILY_TRANSITS_OPEN}`]: [{ type: MONETIZATION_TYPES.IN_APP, screen: SUB_SCREENS.WEB }],
};
