import dayjs from 'dayjs';
import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store/index';
import { getPlanetsInfo } from 'utils/natal-charts';
import { getLocalizedSign } from 'utils/zodiac';
import { getLunarPredictions, getMoonPhase } from 'api/lunar-calendar';
import { getApproximateLocation } from 'api/location';
import { PLANET_IDS } from 'screens/birth-chart/constants';
import { SignTypes } from 'constants/signs';

import { TYPES } from './types';

export const setPredictions = createAction(TYPES.SET_PREDICTIONS);
export const setWidget = createAction(TYPES.SET_WIDGET);
export const setLocation = createAction(TYPES.SET_LOCATION);

export const initLunarWidget = () => {
  return async (dispatch: AppDispatch) => {
    const moonInfo = await dispatch(getMoonInfo());
    getMoonPhase(moonInfo?.sign as unknown as SignTypes).then(widget => {
      dispatch(setWidget(widget));
    });
  };
};

export const initLunarPredictions = () => {
  return async (dispatch: AppDispatch) => {
    const moonInfo = await dispatch(getMoonInfo());
    getLunarPredictions(moonInfo?.sign as unknown as SignTypes).then(predictions => {
      dispatch(setPredictions(predictions));
    });
  };
};

export const getLocation = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      lunarCalendar: { location = {} },
    } = getState();

    let { latitude, longitude } = location;

    if (!latitude || !longitude) {
      const userLocation = await getApproximateLocation();
      dispatch(setLocation(userLocation));
      latitude = userLocation?.latitude;
      longitude = userLocation?.longitude;
    }

    return { latitude, longitude };
  };
};

const getMoonInfo = () => {
  return async (dispatch: AppDispatch) => {
    const { latitude, longitude } = await dispatch(getLocation());

    const year = dayjs().format('YYYY-MM-DD');
    const date = dayjs().format('HH:mm');

    return getPlanetsInfo(year, date, latitude, longitude).find(item => item.name === PLANET_IDS.MOON);
  };
};

export const getMoonSignForDate = (date: string) => {
  return async (dispatch: AppDispatch) => {
    const { latitude, longitude } = await dispatch(getLocation());

    date = dayjs(date).format('YYYY-MM-DD');
    const time = dayjs().format('HH:mm'); // use current time here
    const sign = getPlanetsInfo(date, time, latitude, longitude)?.find(item => item.name === PLANET_IDS.MOON)?.sign;
    return getLocalizedSign(sign);
  };
};
