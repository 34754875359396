import { GENDERS, SINGLE } from 'constants/profile';

import { PROFILE_TYPES, State, ActionType } from './types';

const initialState: State = {
  isProfile: false,
  profileData: {
    name: '',
    email: '',
    date: '2000-01-01',
    time: '',
    gender: GENDERS.FEMALE,
    relationship: SINGLE,
    place: '',
    error: null,
    palmImage: null,
    signs: [],
    id: -1, //todo change to null
    lang: '',
    device_id: '',
    userParams: null,
  },
  isLoading: false,
  isUtcOffsetSet: false,
  isError: false,
  isUserLoyal: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case PROFILE_TYPES.SET_PROFILE:
      return {
        ...state,
        isProfile: true,
        isLoading: false,
        isError: false,
        profileData: {
          ...action.payload,
        },
      };

    case PROFILE_TYPES.UPDATE_USER_PARAMS:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          userParams: {
            ...state.profileData.userParams,
            ...action.payload,
          },
        },
      };

    case PROFILE_TYPES.SET_PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case PROFILE_TYPES.SET_UTC_OFFSET:
      return {
        ...state,
        isUtcOffsetSet: true,
      };

    case PROFILE_TYPES.INIT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case PROFILE_TYPES.SET_USER_LOYAL:
      return {
        ...state,
        isUserLoyal: true,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
