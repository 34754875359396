import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import { uniqWith, intersectionWith } from 'lodash';

import { t, t2 } from 'localization';

import { getPlanetsInfo } from '../../utils/natal-charts';
import * as CHART_CONSTANTS from '../../screens/birth-chart/constants';

import type { Transit } from './types';

const EXTRA_PLANETS = [CHART_CONSTANTS.PLANET_IDS.SUN, CHART_CONSTANTS.PLANET_IDS.MOON];

const STEP_NEXT = {
  [CHART_CONSTANTS.SHORT_TERM]: 40,
  [CHART_CONSTANTS.LONG_TERM]: 1600,
};
const UNIT_NEXT = 'minutes';

const STEP_DURATION = {
  [CHART_CONSTANTS.SHORT_TERM]: 4,
  [CHART_CONSTANTS.LONG_TERM]: 64,
};
const UNIT_DURATION = 'days';

export const generateTransitsForDateTime = (date: string, time: string, lat: number, lon: number, natalPlanets: any, term?: any): Transit[] => {
  let planets = getPlanetsInfo(date, time, lat, lon);
  if (term) {
    planets = filterPlanetsByTerm(planets, term);
  }
  return calculateAspects(planets, natalPlanets);
};

const calculateAspects = (planets: any[], natalPlanets: any[]) => {
  const result: any = [];
  CHART_CONSTANTS.TRANSIT_PLANETS.map(planet => {
    const transitPlanet = planets.find(x => x.name === planet);
    if (transitPlanet) {
      natalPlanets.forEach(natalPlanet => {
        const useExtraOrbis = EXTRA_PLANETS.includes(transitPlanet.name) || EXTRA_PLANETS.includes(natalPlanet.name);
        const diff = Math.abs(Math.ceil(natalPlanet.dms360) - Math.ceil(transitPlanet.dms360));
        const aspect = getAspect(diff, useExtraOrbis, [transitPlanet.name, natalPlanet.name]);

        if (aspect) {
          result.push({
            transitPlanet,
            natalPlanet,
            aspect: {
              name: aspect.name,
              diff: aspect.diff,
              orbis: useExtraOrbis && aspect.extra_orbis ? aspect.extra_orbis : aspect.orbis,
            },
            diff,
          });
        }
      });
    }
  });
  return result;
};

const getAspect = (planetDiff, withExtraOrbis, planets) => {
  const closestAspect = CHART_CONSTANTS.MAP_ASPECTS_TO_TRANSITS.reduce((prev, curr) =>
    Math.abs(curr.diff - planetDiff) < Math.abs(prev.diff - planetDiff) ? curr : prev,
  );

  if (closestAspect.name === CHART_CONSTANTS.OPPOSITION) {
    closestAspect.orbis = planets.reduce((a, b) => a + CHART_CONSTANTS.PLANETS_POINTS[b], 0) / 2;
  }
  const extra_orbis = withExtraOrbis && closestAspect.extra_orbis ? closestAspect.extra_orbis : 0;
  const orbis = extra_orbis ? extra_orbis : closestAspect.orbis;

  return closestAspect.diff + orbis > planetDiff && closestAspect.diff - orbis < planetDiff ? closestAspect : null;
};

export const generateNextTransitTime = (todayTransits, lat, lon, natalPlanets, term) => {
  if (todayTransits?.length && natalPlanets?.length && term && lat && lon) {
    todayTransits = filterTransitsByTerm(todayTransits, term);

    const check = m =>
      !!selectNewTransits(
        todayTransits,
        generateTransitsForDateTime(dayjs(m).format('YYYY-MM-DD'), dayjs(m).format('HH:mm'), lat, lon, natalPlanets, term),
      ).length;
    let m = goForward(STEP_NEXT[term], UNIT_NEXT, check);
    m = goPrecise(m, -STEP_NEXT[term], UNIT_NEXT, check);

    return m;
  } else {
    return null;
  }
};

function selectNewTransits(todayTransits, transits) {
  return _.differenceBy(transits, todayTransits, transitKey);
}

function transitKey(t) {
  return t.transitPlanet.name + '|||' + t.natalPlanet.name + '|||' + t.aspect.name;
}

function filterPlanetsByTerm(planets, term) {
  return _.filter(planets, p => _.includes(CHART_CONSTANTS.PLANETS_BY_TERM[term], p.name));
}

function filterTransitsByTerm(transits, term) {
  return _.filter(transits, t => checkTerm(t, term));
}

export function checkTerm(transit, term) {
  return _.includes(CHART_CONSTANTS.PLANETS_BY_TERM[term], transit.transitPlanet.name);
}

export const getTransitDuration = (transit, lat, lon, natalPlanets) => {
  const check = m =>
    !existTransit(transit, generateTransitsForDateTime(dayjs(m).format('YYYY-MM-DD'), dayjs(m).format('HH:mm'), lat, lon, natalPlanets));

  const term = checkTerm(transit, CHART_CONSTANTS.SHORT_TERM) ? CHART_CONSTANTS.SHORT_TERM : CHART_CONSTANTS.LONG_TERM;

  const step = [STEP_DURATION[term]];

  let start = goForward(-step, UNIT_DURATION, check);
  start = goPrecise(start, step, UNIT_DURATION, m => !check(m)).add(1, 'days');

  let end = goForward(step, UNIT_DURATION, check);
  end = goPrecise(end, -step, UNIT_DURATION, check);

  const startOfTransit = start.add(1, 'days');
  const endOfTransit = end.add(-1, 'days');

  //If the transit lasts one day, then we change the returned data to correctly display the date period
  if (moment(startOfTransit).isAfter(endOfTransit)) {
    return {
      start: endOfTransit,
      end: startOfTransit,
    };
  }

  return {
    start: startOfTransit,
    end: endOfTransit,
  };
};

function existTransit(transit, transits) {
  const k = transitKey(transit);
  return _.find(transits, t => transitKey(t) === k);
}

function goForward(amount, unit, check) {
  const m = moment();
  do {
    m.add(amount, unit);
  } while (!check(m));
  return m;
}

function goPrecise(m, step, unit, check) {
  step = step / 2;
  if (Math.abs(step) < 1) {
    return m;
  } else {
    step = _.round(step);
    m.add(step, unit);
    return goPrecise(m, Math.abs(step) * (check(m) ? -1 : 1), unit, check);
  }
}

export const getUniqTransits = (transits: Transit[]): Transit[] =>
  uniqWith(
    transits,
    (a, b) => a.natalPlanet.name === b.natalPlanet.name && a.transitPlanet.name === b.transitPlanet.name && a.aspect.name === b.aspect.name,
  );

export const getIntersectingTransits = (transitsStart: Transit[], transitsEnd: Transit[]): Transit[] =>
  intersectionWith(
    transitsStart,
    transitsEnd,
    (a, b) => a.natalPlanet.name === b.natalPlanet.name && a.transitPlanet.name === b.transitPlanet.name && a.aspect.name === b.aspect.name,
  );

export const getTransitTitle = (transit: Transit): string => {
  const { natalPlanet, aspect, transitPlanet } = transit;

  const planetName = t2(`SINGS.PLANETS.${transitPlanet.name.toUpperCase()}`);
  const natalPlanetName = t2(`SINGS.PLANETS.${natalPlanet.name.toUpperCase()}`);
  const aspectName = t2(`TRANSITS.ASPECTS_NAMES.${aspect.name.toUpperCase()}`);

  return `${planetName} ${aspectName} ${t('TRANSITS.DESCRIPTION.ARTICLE')} ${natalPlanetName}`;
};
