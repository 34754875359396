import { AspectDescription, TransitDescription } from 'api/transits/interfaces';
import { Planets } from 'constants/planets';
import { SignTypes } from 'constants/signs';
import { ASPECTS_IDS } from 'screens/birth-chart/constants';
import type { PlanetsData } from 'store/natal-charts/types';

export enum TYPES {
  SET_TRANSITS_DATA = 'TRANSITS/SET_TRANSITS_DATA',
  SET_TRANSITS_DESCRIPTION = 'TRANSITS/SET_TRANSITS_DESCRIPTION',
  SET_ASTROEVENT_TRANSITS = 'TRANSITS/SET_ASTROEVENT_TRANSITS',
  SET_ASPECTS_DESCRIPTION = 'TRANSITS/SET_ASPECTS_DESCRIPTION',
  SET_CURRENT_LOCATION = 'TRANSITS/SET_CURRENT_LOCATION',
}

interface AstroeventTransit {
  title: string;
  description: string;
  duration: string;
  connectionCounter: number;
  transit: any;
}

export interface TransitPlanet {
  name: Planets;
  sign: SignTypes;
  dms30: number;
  dms360: number;
}

export interface Aspect {
  name: ASPECTS_IDS;
  diff: number;
  orbis: number;
}

export interface Transit {
  transitPlanet: TransitPlanet;
  natalPlanet: PlanetsData;
  aspect: Aspect;
  diff: number;
}

type TransitsData = {
  [key in string]: any;
};

export interface CurrentLocationValue {
  name: string;
  lat: number;
  lon: number;
}

export interface State {
  transitsData: TransitsData;
  transitsDescription: TransitDescription[];
  currentHouses: any[];
  currentPlanets: any[];
  currentLocation: CurrentLocationValue | null;
  nextShortTermTransitTime: string;
  nextLongTermTransitTime: string;
  astroeventTransit: AstroeventTransit;
  aspectsDescription: AspectDescription[];
}

interface SetData {
  type: typeof TYPES.SET_TRANSITS_DATA;
  payload: {
    transitsData: TransitsData;
    nextShortTermTransitTime: string;
    nextLongTermTransitTime: string;
    currentHouses: any[];
    currentPlanets: any[];
  };
}

interface SetTransitsContent {
  type: typeof TYPES.SET_TRANSITS_DESCRIPTION;
  payload: TransitDescription[];
}

interface SetAstroeventTransit {
  type: typeof TYPES.SET_ASTROEVENT_TRANSITS;
  payload: AstroeventTransit;
}

interface SetAspectsContent {
  type: typeof TYPES.SET_ASPECTS_DESCRIPTION;
  payload: AspectDescription[];
}

interface SetCurrentLocation {
  type: typeof TYPES.SET_CURRENT_LOCATION;
  payload: CurrentLocationValue;
}

export type ActionTypes = SetData | SetTransitsContent | SetAstroeventTransit | SetAspectsContent | SetCurrentLocation;
