import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { isSubBenefitsEnabled as isBenefitsEnabled } from 'store/billing/selectors';
import { setAwaitingNewTimeBalance, getAvailableTime } from 'store/astrologers/time/actions';
import { getHistoryMessages } from 'store/astrologers/chat/actions';
import { closeCurrentModal, showModal } from 'store/modals/actions';
import { fetchWebPurchases, fetchPaymentMethod } from 'store/billing/general-actions';
import { navigate, replace, back } from 'store/navigation/actions';
import { ASTROLOGERS_TRIGGERS_TYPES as TRIGGERS, AstrologersMonetizationTriggers } from 'screens/advisors/screens/monetization/constants/interfaces';
import * as MODALS from 'constants/modals';
import * as ROUTES from 'constants/routes';
import { WEB_POST_MESSAGE_STATUS } from 'constants/billing';
import { PACK_SCREENS_ROUTES, FORCE_TRIGGERS, CHATS_TRIGGERS } from 'screens/advisors/screens/monetization/constants';

import { getAstrologersConsultationsPurchases } from './selectors';
import { TYPES } from './types';

const setFlowStep = createAction(TYPES.SET_FLOW_STEP);
const setStartFlowCallbacks = createAction(TYPES.SET_START_FLOW_CALLBACKS);
export const setCurrentTrigger = createAction(TYPES.SET_CURRENT_TRIGGER);
export const setRenewData = createAction(TYPES.SET_RENEW_DATA);
export const setTransactionData = createAction(TYPES.SET_TRANSACTION_DATA);

export const astrologersOnetimeMonetizationHandler = (trigger: TRIGGERS) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      chat: { currentChatId, currentAstrologerId },
    } = state.astrologers;
    const { device_id, id } = state.profile.profileData;
    const isSubBenefitsEnabled = isBenefitsEnabled(state);

    dispatch(showModal(MODALS.ONE_TIME));
    const listener = (event: MessageEvent) => {
      if (event?.data?.source === 'one-time-payment') {
        const eventParams = {
          chatId: currentChatId,
          userId: id,
          idfm: device_id,
          trigger,
          source: event?.data?.source,
          astrologerId: currentAstrologerId,
          isSubBenefitsEnabled,
        };

        dispatch(closeCurrentModal());
        removeListener();

        if (event?.data?.status === WEB_POST_MESSAGE_STATUS.SUCCESS) {
          const productCode = event?.data?.data?.product_code;
          Analytics.trackEvent('Astrologist', 'Payment_Success', { ...eventParams, productCode });
          dispatch(setAwaitingNewTimeBalance(true));
        } else if (event?.data?.status === WEB_POST_MESSAGE_STATUS.SKIPED) {
          Analytics.trackEvent('Astrologist', 'Payment_Skip', eventParams);
        } else if (event?.data?.status === WEB_POST_MESSAGE_STATUS.BACK) {
          Analytics.trackEvent('Astrologist', 'Payment_Close', eventParams);
        }
      }
    };

    function removeListener() {
      window.removeEventListener('message', listener);
    }

    window.addEventListener('message', listener, false);
  };
};

export const getFlow = () => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const flows = state.remoteConfig?.remoteConfigParams?.astrologerPacksFlows;
    const purchased = getAstrologersConsultationsPurchases(state).length > 0;
    return purchased ? flows.purchased : flows.noPurchased;
  };
};

export const resetFlow = (afterPurchase = false) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        monetization: { startFlowCallbacks: callbacks },
      },
    } = getState();

    back();

    if (afterPurchase && typeof callbacks?.onSuccess === 'function') {
      callbacks.onSuccess();
    }
    if (!afterPurchase && typeof callbacks?.onSkip === 'function') {
      callbacks.onSkip();
    }

    dispatch(setFlowStep(0));
    dispatch(setStartFlowCallbacks({}));
  };
};

export const nextFlowStep = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        monetization: { flowStep: step },
      },
    } = getState();

    const flow = dispatch(getFlow());
    const id = flow[step];
    const route = PACK_SCREENS_ROUTES[id];

    if (route) {
      dispatch(setFlowStep(step + 1));
      replace(route);
    } else {
      dispatch(resetFlow());
    }
  };
};

export const astrologersMonetizationHandler = ({
  trigger,
  onSuccess,
  onSkip,
}: {
  trigger: TRIGGERS;
  onSuccess?: () => void;
  onSkip?: () => void;
}) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      time: { seconds },
      chat: { currentAstrologerId },
      monetization: { flowStep },
    } = state.astrologers;
    const config = state.remoteConfig?.remoteConfigParams?.astrologersMonetizationConfig;
    const secondsLeftToEnableTriggers = state.remoteConfig?.remoteConfigParams?.astrologersSecondsLeftToEnableTriggers;

    const handleSuccess = () => {
      dispatch(setAwaitingNewTimeBalance(true));
      dispatch(fetchWebPurchases());
      dispatch(setCurrentTrigger(''));

      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      if (CHATS_TRIGGERS.includes(trigger) && currentAstrologerId) {
        dispatch(getHistoryMessages(currentAstrologerId));
      }
    };

    const handleSkip = () => {
      dispatch(getAvailableTime());

      if (typeof onSkip === 'function') {
        onSkip();
      }
    };

    // There are some custom logic for not force triggers
    if (!FORCE_TRIGGERS.includes(trigger)) {
      const isTriggerEnabled = config[trigger as AstrologersMonetizationTriggers];
      if (!isTriggerEnabled) {
        return handleSkip();
      }

      if (trigger === TRIGGERS.END_CHAT && seconds >= secondsLeftToEnableTriggers) {
        return handleSkip();
      }
    }

    dispatch(setCurrentTrigger(trigger));

    const flow = dispatch(getFlow());
    const id = flow[flowStep];
    const route = PACK_SCREENS_ROUTES[id] || ROUTES.ADVISORS_PACKS;
    dispatch(setFlowStep(flowStep + 1));
    dispatch(fetchPaymentMethod());

    dispatch(
      setStartFlowCallbacks({
        onSuccess: handleSuccess,
        onSkip: handleSkip,
      }),
    );

    navigate(route);
    return false;
  };
};
