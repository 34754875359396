import { HoroscopeTypes } from 'constants/horoscopes-names';
import { HoroscopeItem } from 'api/horoscope/interfaces';

import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  horoscopes: {},
  userHoroscopes: {
    [HoroscopeTypes.zodiac]: [],
    [HoroscopeTypes.chinese]: [],
    [HoroscopeTypes.indian_moon]: [],
    [HoroscopeTypes.indian_sun]: [],
    [HoroscopeTypes.druid]: [],
    [HoroscopeTypes.mayan]: [],
  },
  userHoroscopesV2: [],
  doDontPredictions: null,
  allSigns: {},
  facts: {},
  isLoading: false,
  isError: false,
  widgetReloadIsRequired: false,
  horoscopeInfo: {
    horoscope_type: null,
    sign: null,
  },
  zodiacAnimationCount: 0,
  todayIndianSunHoroscopeNextSign: {
    horoscope: {} as HoroscopeItem,
    sign: null,
    isPending: false,
  },
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_HOROSCOPE:
      state = {
        ...state,
        horoscopes: {
          ...state.horoscopes,
          [action.payload.type]: action.payload.data.horoscope,
        },
        userHoroscopes: !action.payload.isUser
          ? state.userHoroscopes
          : {
              ...state.userHoroscopes,
              [action.payload.type]: action.payload.data.horoscope,
            },
        facts: action.payload.data.facts || {},
        isLoading: false,
        isError: false,
      };
      break;

    case TYPES.SET_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN:
      state = {
        ...state,
        todayIndianSunHoroscopeNextSign: {
          ...state.todayIndianSunHoroscopeNextSign,
          sign: action.payload.sign,
          horoscope: action.payload.horoscope,
        },
      };
      break;

    case TYPES.SET_PENDING_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN: {
      state = {
        ...state,
        todayIndianSunHoroscopeNextSign: {
          ...state.todayIndianSunHoroscopeNextSign,
          isPending: action.payload,
        },
      };
      break;
    }

    case TYPES.SET_PENDING: {
      state = {
        ...state,
        isLoading: true,
        isError: false,
      };
      break;
    }

    case TYPES.SET_HOROSCOPE_INFO: {
      state = {
        ...state,
        horoscopeInfo: {
          sign: action.payload.sign,
          horoscope_type: action.payload.horoscope_type,
        },
      };
      break;
    }

    case TYPES.SET_ERROR:
      state = {
        ...state,
        horoscopes: {},
        userHoroscopes: initialState.userHoroscopes,
        isLoading: false,
        isError: true,
      };
      break;

    case TYPES.SET_ALL_SIGNS:
      state = {
        ...state,
        allSigns: action.payload,
      };
      break;

    case TYPES.SET_ZODIAC_ANIMATION_COUNT:
      state = {
        ...state,
        zodiacAnimationCount: action.payload,
      };
      break;

    case TYPES.ADD_USER_HOROSCOPE_V2_ITEM:
      state = {
        ...state,
        userHoroscopesV2: [...state.userHoroscopesV2, action.payload],
      };
      break;

    case TYPES.SET_WIDGET_RELOAD_IS_REQUIRED:
      state = {
        ...state,
        widgetReloadIsRequired: action.payload,
      };
      break;

    case TYPES.SET_DO_DONT_PREDICTIONS:
      state = {
        ...state,
        doDontPredictions: action.payload,
      };
      break;

    case TYPES.RESET_USER_HOROSCOPE:
      state = {
        ...state,
        userHoroscopes: initialState.userHoroscopes,
      };
      break;

    case TYPES.RESET_USER_HOROSCOPE_V2:
      state = {
        ...state,
        userHoroscopesV2: initialState.userHoroscopesV2,
      };
      break;

    case PROFILE_TYPES.RESET_USER_DATA:
      state = {
        ...state,
        zodiacAnimationCount: initialState.zodiacAnimationCount,
      };
      break;
  }

  return state;
}
