import { createAction } from 'redux-actions';

import { getHouses, getPlanetsInfo } from 'utils/natal-charts';
import { getPlanetsContent, getHousesContents } from 'api/natal-charts';
import { AppDispatch, AppGetState } from 'store';

import { TYPES, HousesData } from './types';

const setNatalCharts = createAction(TYPES.SET);
const setNatalChartsContent = createAction(TYPES.SET_CONTENT);

const getHouseForDms = (houses: HousesData[], dms: number) => {
  let resultHouse = 0;
  let find = false;

  for (let index = 0; index < houses.length - 1; index++) {
    const currentHouse = houses[index];
    const nextHouse = houses[index + 1];

    if ((nextHouse.dms360 > dms && currentHouse.dms360 <= dms) || (nextHouse.dms360 < currentHouse.dms360 && currentHouse.dms360 <= dms)) {
      resultHouse = currentHouse.house;
      find = true;
    }
  }

  if (!find) {
    resultHouse = 12;
  }

  return resultHouse;
};

export const getPlanetsAndHouses = (date: string, time: string, lat: number, lon: number) => {
  const houses = getHouses(date, time, lat, lon);
  const planets = getPlanetsInfo(date, time, lat, lon).map(x => ({
    ...x,
    house: getHouseForDms(houses, x.dms360),
  }));
  return { planets, houses };
};

export const initNatalCharts = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { date } = state.profile.profileData;
    const { birthTime, birthPlace } = state.birthChart;

    if (date && birthTime && birthPlace) {
      const { planets, houses } = getPlanetsAndHouses(date, birthTime, birthPlace.lat, birthPlace.lon);

      dispatch(
        setNatalCharts({
          planets: planets,
          houses: houses,
        }),
      );
    }
  };
};

export const initNatalChartsContent = () => {
  return (dispatch: AppDispatch) => {
    return Promise.all([getPlanetsContent(), getHousesContents()]).then(([planetsContent, housesContent]) => {
      dispatch(
        setNatalChartsContent({
          planetsContent,
          housesContent,
        }),
      );
    });
  };
};
