import { SignTypes } from 'constants/signs';

import api from '..';

import { Compatibility } from './interfaces';

const COMPATIBILITY = '/compatibility-v2';

export const getCompatibility = (first_sign: SignTypes, second_sign: SignTypes): Promise<Compatibility> => {
  return api.fetch(`${COMPATIBILITY}?first_sign=${first_sign}&second_sign=${second_sign}`, {
    method: 'GET',
  });
};

export const sendCompatibilityEmail = (category: any, email: string) => {
  const processedCategory = category.replace('_', '-').toLowerCase();
  return api.fetch(`${COMPATIBILITY}/${processedCategory}/add-user`, {
    method: 'POST',
    data: {
      email,
    },
  });
};
