import { PlaceValue } from 'interfaces/birth-chart';

import { PROFILE_TYPES } from '../profile/types';

import {
  SET_ONBOARDING_DONE,
  SET_BIRTH_PLACE,
  SET_BIRTH_TIME,
  SET_HOUR_FORMAT_BIRTH_TIME,
  SET_REFRESHED,
  SET_ONBOARDING_STEP,
  RESET_DATA,
  State,
  ActionType,
} from './types';

const initialState: State = {
  onboardingDone: false,
  birthPlace: {} as PlaceValue,
  birthTime: null,
  birthHourFormatTime: '',
  needToRefreshBirthChart: false,
  step: 0,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case SET_ONBOARDING_DONE:
      return {
        ...state,
        onboardingDone: true,
      };
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case SET_BIRTH_PLACE:
      return {
        ...state,
        birthPlace: action.payload,
        needToRefreshBirthChart: state.needToRefreshBirthChart || state.birthPlace?.name !== action.payload?.name,
      };
    case SET_BIRTH_TIME:
      return {
        ...state,
        birthTime: action.payload,
        needToRefreshBirthChart: state.needToRefreshBirthChart || state.birthTime !== action.payload,
      };
    case SET_HOUR_FORMAT_BIRTH_TIME:
      return {
        ...state,
        birthHourFormatTime: action.payload,
      };
    case SET_REFRESHED:
      return {
        ...state,
        needToRefreshBirthChart: false,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    case RESET_DATA:
      return initialState;
    default:
      return state;
  }
}
