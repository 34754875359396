import { HoroscopeTypes } from 'constants/horoscopes-names';
import { SignTypes } from 'constants/signs';

import api from '..';

import type { Horoscope, HoroscopeSigns, UserHoroscopeV2Request, UserHoroscopeV2Response, UserHoroscopeV2Item } from './interfaces';

const HOROSCOPES = '/horoscope';
const ALL_SIGNS = '/all-signs';
const USER_HOROSCOPE_V2 = '/horoscope/generate';

export const getHoroscope = (horoscope_type: HoroscopeTypes, sign: SignTypes, isRandomHoroscope = false): Promise<Horoscope> => {
  return api.fetch(`${HOROSCOPES}?horoscope_type=${horoscope_type}&sign=${sign}&early=${!isRandomHoroscope}`, {
    method: 'GET',
  });
};

export const getAllSigns = (): Promise<HoroscopeSigns> => {
  return api.fetch(`${HOROSCOPES}${ALL_SIGNS}`, {
    method: 'GET',
  });
};

export const getUserHoroscopeV2 = async (data: UserHoroscopeV2Request): Promise<UserHoroscopeV2Response> => {
  try {
    const response = (await api.fetch(`${USER_HOROSCOPE_V2}`, {
      method: 'POST',
      data,
    })) as UserHoroscopeV2Item[];

    return checkAndFixUserHoroscopeV2Items(response);
  } catch (err) {
    console.log('[ERROR LOAD USER HOROSCOPE V2]', err);
    return [];
  }
};

const checkAndFixUserHoroscopeV2Items = (items: UserHoroscopeV2Item[]): UserHoroscopeV2Item[] =>
  items
    ?.filter(item => !!item?.data)
    ?.map(item => ({
      ...item,
      data: {
        // This prop is being generated by AI so we need to check it
        ...item.data,
        powerAndFocus: fixStr(item.data?.powerAndFocus),
        troubles: fixStr(item.data?.troubles),
        blocks:
          item.data?.blocks?.map(block => ({
            ...block,
            title: fixStr(block?.title),
            text: fixStr(block?.text),
          })) || [],
        tips: {
          ...item.data?.tips,
          love: fixStr(item.data?.tips?.love),
          warnings: fixStr(item.data?.tips?.warnings),
          work: fixStr(item.data?.tips?.work),
          recommendations: fixStr(item.data?.tips?.recommendations),
        },
        doDont: {
          ...item.data?.doDont,
          do: fixStr(item.data?.doDont?.do),
          dont: fixStr(item.data?.doDont?.dont),
        },
      },
    })) || [];

const fixStr = (str: any): string => (!!str && typeof str === 'string' ? str : '');
