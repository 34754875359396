import dayjs from 'dayjs';

import { PERIODS_IDS } from 'constants/periods';
import { generateTransitsForDateTime, getTransitDuration } from 'store/transits/utils';
import type { PlanetsData } from 'store/natal-charts/types';
import type { Transit } from 'store/transits/types';
import { TRANSIT_DATE_FORMAT, TRANSIT_TIME_FORMAT } from 'store/transits/constants';
import { getUniqTransits } from 'store/transits/utils';
import { LONG_TERM, SHORT_TERM } from 'screens/birth-chart/constants';

import type { UserHoroscopeV2Period } from './types';

const LONG_TERM_TRANSIT_PERIODS = [PERIODS_IDS.MONTH, PERIODS_IDS.YEAR];
const DEFAULT_TRANSIT_TIME = '12:00';

export const generateTransitsForUserHoroscopeV2 = (params: {
  period: UserHoroscopeV2Period;
  lat: number;
  lon: number;
  natalPlanets: PlanetsData[];
}) => {
  const { period, lat, lon, natalPlanets } = params;

  const term = LONG_TERM_TRANSIT_PERIODS.includes(period) ? LONG_TERM : SHORT_TERM;
  const dateTimeList = getTransitsDateTimeListByPeriod(period);

  const allTransits = dateTimeList.reduce((acc, dateTime) => {
    const { date, time } = dateTime;
    const transits = generateTransitsForDateTime(date, time, lat, lon, natalPlanets, term);
    return [...acc, ...transits];
  }, [] as Transit[]);

  const uniqTransits = getUniqTransits(allTransits);

  return convertTransitsForHoroscopeV2Request({ transits: uniqTransits, lat, lon, natalPlanets });
};

const getTransitsDateTimeListByPeriod = (period: UserHoroscopeV2Period) => {
  switch (period) {
    case PERIODS_IDS.TODAY: {
      return [
        {
          date: dayjs().format(TRANSIT_DATE_FORMAT),
          time: dayjs().format(TRANSIT_TIME_FORMAT),
        },
      ];
    }
    case PERIODS_IDS.TOMORROW: {
      return [
        {
          date: dayjs().add(1, 'day').format(TRANSIT_DATE_FORMAT),
          time: DEFAULT_TRANSIT_TIME,
        },
      ];
    }
    case PERIODS_IDS.WEEK: {
      return Array(7)
        .fill(undefined)
        .map((_, i) => ({
          date: dayjs().day(i).format(TRANSIT_DATE_FORMAT),
          time: DEFAULT_TRANSIT_TIME,
        }));
    }
    case PERIODS_IDS.MONTH: {
      return [dayjs().startOf('month'), dayjs().endOf('month')].map(d => ({
        date: d.format(TRANSIT_DATE_FORMAT),
        time: DEFAULT_TRANSIT_TIME,
      }));
    }
    case PERIODS_IDS.YEAR: {
      return Array(12)
        .fill(undefined)
        .map((_, i) => ({
          date: dayjs().month(i).endOf('month').format(TRANSIT_DATE_FORMAT),
          time: DEFAULT_TRANSIT_TIME,
        }));
    }
  }
};

export const convertTransitsForHoroscopeV2Request = (params: { transits: Transit[]; lat: number; lon: number; natalPlanets: PlanetsData[] }) => {
  const { transits, lat, lon, natalPlanets } = params;

  return transits.map(t => {
    const duration = getTransitDuration(t, lat, lon, natalPlanets);

    return {
      natalPlanet: {
        name: t.natalPlanet.name,
        sign: t.natalPlanet.sign,
        house: t.natalPlanet.house,
      },
      transitPlanet: {
        name: t.transitPlanet.name,
        sign: t.transitPlanet.sign,
      },
      aspect: {
        name: t.aspect.name,
        start: dayjs(duration.start.toDate()).format(),
        end: dayjs(duration.end.toDate()).format(),
      },
    };
  });
};
