import { Horoscope, HoroscopeItem, UserHoroscopeV2Item } from 'api/horoscope/interfaces';
import { HoroscopeTypes } from 'constants/horoscopes-names';
import { SignTypes } from 'constants/signs';
import type { PERIODS_IDS } from 'constants/periods';
import { MoonSign } from 'api/lunar-calendar/interfaces';

import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET_HOROSCOPE = 'HOROSCOPES/SET_HOROSCOPE',
  SET_ERROR = 'HOROSCOPES/HOROSCOPE_SET_ERROR',
  SET_ALL_SIGNS = 'HOROSCOPES/SET_ALL_SIGNS',
  SET_PENDING = 'HOROSCOPES/HOROSCOPE_SET_PENDING',
  SET_HOROSCOPE_INFO = 'HOROSCOPES/SET_HOROSCOPE_INFO',
  SET_DO_DONT_PREDICTIONS = 'HOROSCOPES/SET_DO_DONT_PREDICTIONS',
  SET_ZODIAC_ANIMATION_COUNT = 'HOROSCOPES/SET_ZODIAC_ANIMATION_COUNT',
  SET_PENDING_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN = 'HOROSCOPES/SET_PENDING_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN',
  SET_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN = 'HOROSCOPES/SET_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN',
  ADD_USER_HOROSCOPE_V2_ITEM = 'HOROSCOPES/ADD_USER_HOROSCOPE_V2_ITEM',
  SET_WIDGET_RELOAD_IS_REQUIRED = 'HOROSCOPES/SET_WIDGET_RELOAD_IS_REQUIRED',
  RESET_USER_HOROSCOPE = 'HOROSCOPES/RESET_USER_HOROSCOPE',
  RESET_USER_HOROSCOPE_V2 = 'HOROSCOPES/RESET_USER_HOROSCOPE_V2',
}

export type UserHoroscopeV2Period = PERIODS_IDS.TODAY | PERIODS_IDS.TOMORROW | PERIODS_IDS.WEEK | PERIODS_IDS.MONTH | PERIODS_IDS.YEAR;

export interface DoDontPredictions extends MoonSign {}

export interface State {
  horoscopes: {};
  userHoroscopes: {
    [key in HoroscopeTypes]: HoroscopeItem[];
  };
  userHoroscopesV2: UserHoroscopeV2Item[];
  doDontPredictions: DoDontPredictions[] | null;
  allSigns: Partial<AllSigns>;
  facts: {};
  isLoading: boolean;
  isError: boolean;
  widgetReloadIsRequired: boolean;
  horoscopeInfo: {
    sign: SignTypes | null;
    horoscope_type: HoroscopeTypes | null;
  };
  zodiacAnimationCount: number;
  todayIndianSunHoroscopeNextSign: {
    horoscope: HoroscopeItem;
    sign: SignTypes | null;
    isPending: boolean;
  };
}

export type AllSigns = {
  [key in HoroscopeTypes]: any;
};

interface SetHoroscope {
  type: typeof TYPES.SET_HOROSCOPE;
  payload: {
    type: HoroscopeTypes;
    data: Horoscope;
    isUser: boolean;
  };
}

interface SetAllSigns {
  type: typeof TYPES.SET_ALL_SIGNS;
  payload: any;
}

interface SetHoroscopeInfo {
  type: typeof TYPES.SET_HOROSCOPE_INFO;
  payload: {
    sign: SignTypes;
    horoscope_type: HoroscopeTypes;
  };
}

interface SetError {
  type: typeof TYPES.SET_ERROR;
}

interface SetPending {
  type: typeof TYPES.SET_PENDING;
}
interface SetDoDontPredictions {
  type: typeof TYPES.SET_DO_DONT_PREDICTIONS;
  payload: DoDontPredictions[] | null;
}

interface SetTodayIndianSunHoroscopeForNextSign {
  type: typeof TYPES.SET_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN;
  payload: {
    sign: SignTypes;
    horoscope: HoroscopeItem;
  };
}

interface SetPendingTodayIndianSunHoroscopeForNextSign {
  type: typeof TYPES.SET_PENDING_TODAY_INDIAN_SUN_HOROSCOPE_FOR_NEXT_SIGN;
  payload: boolean;
}

interface SetZodiacAnimationCount {
  type: typeof TYPES.SET_ZODIAC_ANIMATION_COUNT;
  payload: number;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface AddUserHoroscopeV2Item {
  type: typeof TYPES.ADD_USER_HOROSCOPE_V2_ITEM;
  payload: UserHoroscopeV2Item;
}

interface SetWidgetRefresh {
  type: typeof TYPES.SET_WIDGET_RELOAD_IS_REQUIRED;
  payload: boolean;
}
interface ResetUserHoroscope {
  type: typeof TYPES.RESET_USER_HOROSCOPE;
}

interface ResetUserHoroscopeV2 {
  type: typeof TYPES.RESET_USER_HOROSCOPE_V2;
}

export type ActionTypes =
  | SetHoroscope
  | SetAllSigns
  | SetHoroscopeInfo
  | SetError
  | SetPending
  | SetDoDontPredictions
  | SetPendingTodayIndianSunHoroscopeForNextSign
  | SetZodiacAnimationCount
  | ResetUserData
  | SetTodayIndianSunHoroscopeForNextSign
  | AddUserHoroscopeV2Item
  | SetWidgetRefresh
  | ResetUserHoroscope
  | ResetUserHoroscopeV2;
