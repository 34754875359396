import * as ROUTES from './routes';

export const TIMER = 'timer';
export const SPECIAL_OFFER = 'special_offer';
export const PROMO_OFFER = 'promo_offer';
export const VERTICAL = 'vertical_products';
export const FAMILY = 'family';
export const PALM = 'palm';
export const POST = 'post';
export const VERTICAL_ONE = 'vertical_one';
export const WEB = 'web';

export const MAP = {
  [TIMER]: ROUTES.SUBSCRIPTION_TIMER,
  [SPECIAL_OFFER]: ROUTES.SUBSCRIPTION_SPECIAL_OFFER,
  [PROMO_OFFER]: ROUTES.SUBSCRIPTION_PROMO_OFFER,
  [VERTICAL]: ROUTES.SUBSCRIPTION_VERTICAL_PRODUCTS,
  [FAMILY]: ROUTES.SUBSCRIPTION_FAMILY,
  [PALM]: ROUTES.SUBSCRIPTION_PALM,
  [POST]: ROUTES.SUBSCRIPTION_POST,
  [VERTICAL_ONE]: ROUTES.SUBSCRIPTION_VERTICAL_ONE_PRODUCT,
  [WEB]: ROUTES.SUBSCRIPTION_WEB,
};
