import dayjs from 'dayjs';
import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import { getLocation } from 'store/lunar-calendar/actions';
import { t, t2 } from 'localization';
import { getAspectsContent, getTransitsContent } from 'api/transits';
import { PERIODS_IDS } from 'constants/periods';
import * as CHART_CONSTANTS from 'screens/birth-chart/constants';
import { getApproximateLocation } from 'api/location';

import { getPlanetsAndHouses } from '../natal-charts/actions';

import { CurrentLocationValue, TYPES } from './types';
import { generateTransitsForDateTime, generateNextTransitTime, checkTerm, getTransitDuration } from './utils';

const setTransitsData = createAction(TYPES.SET_TRANSITS_DATA);
const setTransitsContent = createAction(TYPES.SET_TRANSITS_DESCRIPTION);
const setAstroeventTransit = createAction(TYPES.SET_ASTROEVENT_TRANSITS);
const setAspectsContent = createAction(TYPES.SET_ASPECTS_DESCRIPTION);
export const setCurrentLocation = createAction(TYPES.SET_CURRENT_LOCATION);

const createTransits = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      birthChart: { birthPlace, birthTime },
      profile: {
        profileData: { date },
      },
      transits: { transitsData, currentLocation },
    } = getState();

    const time = birthTime ?? '12:00';
    let latitude = currentLocation?.lat;
    let longitude = currentLocation?.lon;

    if (!latitude || !longitude) {
      const coords = await dispatch(getLocation());
      latitude = coords.latitude;
      longitude = coords.longitude;
    }

    const { planets } = getPlanetsAndHouses(date, time, birthPlace?.lat ?? latitude, birthPlace?.lon ?? longitude);

    let todayTransits = transitsData[PERIODS_IDS.TODAY];

    if (!todayTransits?.length) {
      todayTransits = generateTransitsForDateTime(dayjs().format('YYYY-MM-DD'), dayjs().format('HH:mm'), latitude, longitude, planets);
    }

    const transits = todayTransits?.filter(item => checkTerm(item, CHART_CONSTANTS.SHORT_TERM));
    return { transits, planets, latitude, longitude };
  };
};

const getTransit = (transits = [], currentTransit, wasConnection) => {
  if (!transits?.length) {
    return undefined;
  }

  if (!currentTransit) {
    return transits[0];
  }

  let transitIndex = transits.findIndex(item => Object.entries(item).toString() === Object.entries(currentTransit).toString());

  if (transitIndex === -1 || !wasConnection) {
    return currentTransit;
  }

  transitIndex += 1;

  if (transitIndex >= transits.length) {
    return transits[0];
  }

  return transits[transitIndex];
};

export const generateAstroeventTransitData = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      transits: {
        astroeventTransit: { transit: currentTransit, connectionCounter = 0 },
      },
      astrologers: {
        modals: {
          astroeventCounters: { resetCount },
        },
      },
    } = getState();
    const { transits, planets, latitude, longitude } = await dispatch(createTransits());
    const transit = getTransit(transits, currentTransit, resetCount > connectionCounter);
    if (!transit) {
      return false;
    }

    const { natalPlanet, aspect, transitPlanet } = transit;
    const planet = transitPlanet?.name ? t2(`SINGS.PLANETS.${transitPlanet.name.toUpperCase()}`) : '';
    const natalPlanetName = natalPlanet?.name ? t2(`SINGS.PLANETS.${natalPlanet.name.toUpperCase()}`) : '';
    const aspectName = aspect?.name ? t2(`TRANSITS.ASPECTS_NAMES.${aspect.name.toUpperCase()}`) : '';
    const title = `${planet} ${aspectName} ${t('TRANSITS.DESCRIPTION.ARTICLE')} ${natalPlanetName}`;

    const description = t2(`TRANSITS.ASPECTS.${aspect?.name?.toUpperCase()}`, {
      transitPlanet: transitPlanet.name,
      natalPlanet: natalPlanet.name,
    });

    const durationData = getTransitDuration(transit, latitude, longitude, planets);
    const duration = t(`TRANSITS.DURATION`, {
      start: durationData?.start?.format('MMM DD, YYYY'),
      end: durationData?.end?.format('MMM DD, YYYY'),
    });

    dispatch(setAstroeventTransit({ title, description, duration, transit, connectionCounter: resetCount }));
  };
};

export const generateTransits = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      birthChart: { birthPlace },
      transits: { currentLocation },
      natalCharts: { planets: natalPlanets },
    } = getState();

    let currentUserLocation: CurrentLocationValue | null = currentLocation;

    if (!currentLocation) {
      try {
        const location = await getApproximateLocation();
        const locationParams: CurrentLocationValue = {
          name: location.time_zone ?? birthPlace?.name,
          lat: location?.latitude ?? birthPlace?.lat,
          lon: location?.longitude ?? birthPlace?.lon,
        };
        dispatch(setCurrentLocation(locationParams));
        currentUserLocation = locationParams;
      } catch (e) {
        console.log('ERROR getApproximateLocation', e);
        currentUserLocation = null;
      }
    }

    if (currentUserLocation) {
      const { lat, lon } = currentUserLocation;

      const { houses, planets } = getPlanetsAndHouses(dayjs().format('YYYY-MM-DD'), dayjs().format('HH:mm'), lat, lon);

      const twoDaysBeforeYesterdayTransits = generateTransitsForDateTime(
        dayjs().add(-3, 'days').format('YYYY-MM-DD'),
        '00:00',
        lat,
        lon,
        natalPlanets,
      );
      const beforeYesterdayTransits = generateTransitsForDateTime(dayjs().add(-2, 'days').format('YYYY-MM-DD'), '00:00', lat, lon, natalPlanets);
      const yesterdayTransits = generateTransitsForDateTime(dayjs().add(-1, 'days').format('YYYY-MM-DD'), '00:00', lat, lon, natalPlanets);

      const todayTransits = generateTransitsForDateTime(dayjs().format('YYYY-MM-DD'), dayjs().format('HH:mm'), lat, lon, natalPlanets);

      const tomorrowTransits = generateTransitsForDateTime(dayjs().add(1, 'days').format('YYYY-MM-DD'), '00:00', lat, lon, natalPlanets);
      const afterTomorrowTransits = generateTransitsForDateTime(dayjs().add(2, 'days').format('YYYY-MM-DD'), '00:00', lat, lon, natalPlanets);
      const twoDaysAfterTomorrowTransits = generateTransitsForDateTime(dayjs().add(3, 'days').format('YYYY-MM-DD'), '00:00', lat, lon, natalPlanets);

      dispatch(
        setTransitsData({
          transitsData: {
            [PERIODS_IDS.TWO_DAYS_BEFORE_YESTERDAY]: twoDaysBeforeYesterdayTransits,
            [PERIODS_IDS.BEFORE_YESTERDAY]: beforeYesterdayTransits,
            [PERIODS_IDS.YESTERDAY]: yesterdayTransits,

            [PERIODS_IDS.TODAY]: todayTransits,

            [PERIODS_IDS.TOMORROW]: tomorrowTransits,
            [PERIODS_IDS.AFTER_TOMORROW]: afterTomorrowTransits,
            [PERIODS_IDS.TWO_DAYS_AFTER_TOMORROW]: twoDaysAfterTomorrowTransits,
          },
          nextShortTermTransitTime: generateNextTransitTime(todayTransits, lat, lon, natalPlanets, CHART_CONSTANTS.SHORT_TERM),
          nextLongTermTransitTime: generateNextTransitTime(todayTransits, lat, lon, natalPlanets, CHART_CONSTANTS.LONG_TERM),
          currentHouses: houses,
          currentPlanets: planets,
        }),
      );

      dispatch(generateAstroeventTransitData());
    }
  };
};

export const initTransitsContent = () => {
  return async (dispatch: AppDispatch) => {
    const [transitsContent, aspectsContent] = await Promise.all([getTransitsContent(), getAspectsContent()]);
    dispatch(setTransitsContent(transitsContent));
    dispatch(setAspectsContent(aspectsContent));
  };
};
