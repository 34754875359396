import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { HoroscopeTypes } from 'constants/horoscopes-names';
import { SignTypes } from 'constants/signs';
import { SHORT_PATTERN } from 'constants/moment';
import { RootState } from 'store';
import { getChartsData, getFullBiorhythms } from 'utils/biorithm';
import { getDestinyNumber, getLuckNumber, getNumberOfDay, getNumberOfYear } from 'utils/numberology';

import { ProfileData } from './types';

export const selects = {
  DAY: 'NUMBER_OF_DAY',
  DESTINY: 'DESTINY',
  YEAR: 'NUMBER_OF_YEAR',
  LUCK: 'LUCK',
};

export const POINTS_COUNT = 31;

type GetProfileDataType = (state: RootState) => ProfileData;
type GetIsFullProfileType = (state: RootState) => boolean;
type GetBiorhythmsType = (state: RootState) => any[];
type GetStoreType = (state: RootState) => RootState;
type GetAllSignsType = (state: RootState) => any;
type GetIsProfile = (state: RootState) => boolean;
type GetAuthIsSuccessful = (state: RootState) => boolean;

const getProfileData: GetProfileDataType = state => state.profile.profileData || {};
const getIsFullProfile: GetIsFullProfileType = state => state.app.isFullProfile;
const getBiorhythms: GetBiorhythmsType = state => state.biorhythms.biorhythms;
const getStoreState: GetStoreType = state => state;
const getAllSigns: GetAllSignsType = state => state.horoscope.allSigns;
const getIsProfile: GetIsProfile = (state: RootState) => state.profile.isProfile;
const getAuthIsSuccessful: GetAuthIsSuccessful = (state: RootState) => state.auth.isSuccessful;

export const selectNumerology = createSelector<[GetProfileDataType, GetIsFullProfileType], { name: string; number: string | number }[] | null>(
  [getProfileData, getIsFullProfile],
  ({ date, name }, isFullProfile) =>
    isFullProfile
      ? [
          { name: selects.DAY, number: getNumberOfDay(date) },
          { name: selects.DESTINY, number: getDestinyNumber(date) },
          { name: selects.YEAR, number: getNumberOfYear(date) },
          { name: selects.LUCK, number: getLuckNumber(date, name) },
        ]
      : null,
);

export const selectBiorithmValues = createSelector([getProfileData], ({ date }) => getChartsData(date, POINTS_COUNT));

export const selectBiorithmAverage = createSelector([getProfileData, getBiorhythms], ({ date }, biorhythms) => getFullBiorhythms(date, biorhythms));

export const selectZodiacSign = createSelector<[GetProfileDataType, GetIsFullProfileType], SignTypes | undefined>(
  [getProfileData, getIsFullProfile],
  ({ signs = [] }) => {
    const zodiac = signs.find((item: any) => item.type === HoroscopeTypes.zodiac) as any;

    return zodiac?.sign ? zodiac.sign.toUpperCase() : undefined;
  },
);

export const selectStoreDataForCreative = createSelector(
  [getStoreState, selectBiorithmAverage],
  ({ profile, horoscope, bestMatches, matches }, averageBiorhythms) => ({
    profile,
    horoscope,
    bestMatches,
    matches,
    averageBiorhythm: averageBiorhythms?.TODAY,
  }),
);

export const selectAge = createSelector([getProfileData], profile => {
  if (!profile.date) {
    return -1;
  }
  return moment().diff(moment(profile.date, SHORT_PATTERN), 'years');
});

export const selectIndianSignNextFromUser = createSelector([getAllSigns, getProfileData], (allSigns, { signs }) => {
  if (!_.isEmpty(allSigns) && !isEmpty(signs)) {
    const signsInfo = _.get(allSigns, HoroscopeTypes.indian_sun);
    const userSign = _.find(signs, { type: HoroscopeTypes.indian_sun })?.sign;
    let index = _.findIndex(signsInfo, { name: userSign }) + 1 || 0;

    if (index > 11) {
      index = 0;
    }

    return signsInfo[index]?.name;
  }

  return undefined;
});

export const selectUserProfileIsLoaded = createSelector([getProfileData, getIsProfile, getAuthIsSuccessful], (profileData, isProfile, isAuth) => {
  return isProfile && isAuth && profileData.device_id && profileData.device_id !== 'unknown';
});
