import { Platform } from 'react-native';

import { ANDROID_WIDGETS_LIST, IOS_WIDGETS_LIST, WEB_WIDGETS_LIST, READING_PAGE_WIDGETS, COMPATIBILITY_WIDGETS_LIST } from 'constants/widgets';
import { ONBOARDING_IDS } from 'constants/onboarding-types';
import { PRODUCT_IDS, SUBSCRIPTION_IDS, OFFERS, WEB_ONE_TIME_PRODUCT_CODES, WEB_TIP_PRODUCTS, PAYMENT_METHODS } from 'constants/billing';
import { ZENDESK_TOKEN, SUPPORT_EMAIL, HELPDESK_TEAM_ID } from 'constants/general';
import { BADGE_ALIGN, PRICE_LOCATION, PRODUCTS, SUB_FAMILY_TYPES, SUB_TYPES, TEXTS_ALIGN } from 'constants/subscription';
import * as SUB_SCREENS from 'constants/subscription-screens';
import { PUSH_BEHAVIOR } from 'constants/messaging';
import * as ROUTES from 'constants/routes';
import { NONE, NUMBER } from 'constants/tab-bar-badges';
import { BANNERS as GUIDES_BANNERS } from 'constants/guides';
import { MODAL_CLOSE_TYPES } from 'constants/modal-close-types';
import { MonetizationConfig } from 'constants/monetization-types';
import { CONTACT_US_METHODS } from 'constants/form';
import { ADVISORS_ONB_PAGES_IDS, ASTROLOGERS_ONBOARDING_INAPP } from 'store/astrologers/onboarding/types';
import { ADVISORS_CATALOG_CUSTOM_BLOCK_IDS, ADVISORS_CATEGORIES } from 'screens/advisors/screens/catalog/types';
import { SKIP_BUTTON_PLACE } from 'screens/onboarding/palm/types';
import { TOP_TABS_IDS } from 'screens/birth-chart/constants';
import { ADVISOR_NOTIFICATIONS } from 'constants/notification-center';
import {
  astrologersMonetizationConfig,
  astrologerConsultationPacks,
  astrologerPackSpecialOffer,
  astrologerPackWelcomeOffer,
  astrologerPackSpecialOffer2,
  astrologerPacksFlows,
  astrologerCreditsConfig,
} from 'screens/advisors/screens/monetization/constants';
import { defaultAstroCalendarConfig, defaultAstroCalendarNotificationConfig } from 'screens/astro-calendar/constants';
import { ASTROLOGERS_PROMO_PLACES, FIRST_SESSION_TEMPLATES } from 'screens/advisors/constants';

import {
  Ads,
  DefaultParams,
  Rescan,
  SubscriptionFamily,
  SubscriptionPalm,
  SubscriptionPost,
  SubscriptionPromoOffer,
  SubscriptionSpecialOffer,
  SubscriptionTimer,
  SubscriptionVertical,
  SubscriptionVerticalOneProduct,
  SurveyConfig,
  PushConfig,
  RateUs,
  WebOnetimeProduct,
  ChatTooltipConfig,
  AstroeventModalConfig,
} from './interfaces';
import { monetizationConfigAndroid, monetizationConfigIos, monetizationConfigWeb } from './monetization';

const subscriptions: string[] = SUBSCRIPTION_IDS;
const products: string[] = PRODUCT_IDS;
const authZendescToken = ZENDESK_TOKEN as string;
const supportEmail = SUPPORT_EMAIL as string;

const verticalProductsSubscriptionDefaultParamsIos: SubscriptionVertical = {
  title: '',
  hideSecurityText: false,
  subscribeOnToggleProduct: false,
  showTerms: true,
  animatedBtn: true,
  priceText: 'Start your 3-day FREE trial',
  hidePriceInfo: false,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.ABOVE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.MONTH_F,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{priceweek}',
      title: '',
      rightText: '',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.WEEK_H,
      isActive: true,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{priceweekwop}/week',
      title: 'Week - Now {price}',
      rightText: '3 days free',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.YEAR_D,
      badgeTitle: '90% save',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '',
      title: 'Year - First {price}',
      rightText: '{priceweek}',
      rightBottomText: '{price}',
      btnTitle: '',
    },
  ],
};

const verticalProductsSubscriptionDefaultParamsAndroid: SubscriptionVertical = {
  title: '',
  hideSecurityText: false,
  subscribeOnToggleProduct: false,
  showTerms: true,
  animatedBtn: true,
  priceText: '',
  hidePriceInfo: true,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.YEAR_C,
      badgeTitle: 'Save 92%',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}🔥',
      rightBottomText: '',
      btnTitle: '',
      isActive: true,
    },
    {
      productId: PRODUCTS.MONTH_D,
      badgeTitle: 'Save 50%',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.WEEK_F,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}',
      rightBottomText: '',
      btnTitle: '',
    },
  ],
};

const familySubscriptionDefaultParams: SubscriptionFamily = {
  isSwitchEnabledByDefault: true,
  [SUB_FAMILY_TYPES.BASIC]: {
    hideSecurityText: false,
    subscribeOnToggleProduct: false,
    showTerms: true,
    animatedBtn: true,
    priceLocation: PRICE_LOCATION.NONE,
    priceText: 'Free for your family',
    hidePriceInfo: false,
    closeBtnTimeout: 0,
    isCrossedPriceText: true,
    buttonsTextsPosition: TEXTS_ALIGN.RIGHT,
    buttonsWithCheckbox: true,
    buttons: [
      {
        badgeTitle: 'Save 88%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.YEAR_E,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: true,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: 'Save 50%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.MONTH_C,
        title: '{period}',
        subTitle: '{priceweek}',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: '',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.WEEK_L,
        title: '{period}',
        subTitle: '{priceweek}',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
    ],
  },
  [SUB_FAMILY_TYPES.FAMILY]: {
    subscribeOnToggleProduct: false,
    priceLocation: PRICE_LOCATION.NONE,
    closeBtnTimeout: 0,
    priceText: 'Free for your family',
    isCrossedPriceText: false,
    hidePriceInfo: false,
    hideSecurityText: false,
    buttonsTextsPosition: TEXTS_ALIGN.RIGHT,
    buttonsWithCheckbox: true,
    showTerms: true,
    animatedBtn: true,
    buttons: [
      {
        badgeTitle: 'Save 88%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.FAMILY_YEAR,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: true,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: 'Save 58%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.FAMILY_MONTH,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: '',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.WEEK_E,
        title: '{period}',
        subTitle: '{introprice}/1st week',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: 'TRY 7-DAY TRIAL',
      },
    ],
  },
};

const palmSubscriptionParams: SubscriptionPalm = {
  title: '',
  btnTitle: '',
  priceText: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_H,
    android: PRODUCTS.WEEK_A,
  }) as string,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.INSIDE,
  showTerms: Platform.select<boolean>({
    android: true,
    ios: false,
  }),
};

const postSubscriptionParams: SubscriptionPost = {
  title: '',
  subTitle: '',
  btnTitle: '',
  priceText: '',
  hideBtnBadge: false,
  btnBadgeTitle: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_H,
    android: PRODUCTS.WEEK_F,
  }) as string,
  benefits: [],
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  showTerms: false,
};

const verticalOneProductSubscriptionParams: SubscriptionVerticalOneProduct = {
  title: '',
  btnTitle: '',
  priceText: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_N,
    android: PRODUCTS.WEEK_A,
  }) as string,
  benefits: [],
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  showTerms: Platform.select<boolean>({
    android: true,
    ios: false,
  }),
  hideSecurityText: false,
  btnBadgeTitle: '',
  hideBtnBadge: false,
};

const subscriptionTimer: SubscriptionTimer = {
  showTerms: true,
  time: 240,
  products: [
    {
      title: 'Month',
      productId: Platform.select({
        ios: PRODUCTS.MONTH_F,
        android: PRODUCTS.MONTH_D,
      }) as string,
      isActive: true,
    },
    {
      title: 'Week',
      productId: Platform.select({
        ios: PRODUCTS.WEEK_H,
        android: PRODUCTS.WEEK_F,
      }) as string,
    },
  ],
};

const subscriptionSpecialOffer: SubscriptionSpecialOffer = {
  productId: Platform.select({
    ios: PRODUCTS.YEAR_C,
    android: PRODUCTS.YEAR_C,
  }) as string,
  priceFormat: '{price}/year',
  priceDescFormat: 'thats only {pricemonth}',
  discount: 10,
  time: 240,
  btnTitle: 'TRY 3-DAY TRIAL',
  closeBtnTimeout: 1,
  showTerms: true,
  buttonAnimation: 'waves',
};

const subscriptionPromoOffer: SubscriptionPromoOffer = {
  title: '',
  subTitle: '',
  hideSecurityText: false,
  showTerms: true,
  animatedBtn: true,
  priceText: '',
  hidePriceInfo: false,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.ABOVE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T_A,
      isActive: true,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo 2',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T_B,
      badgeTitle: '90% save',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo 3',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
  ],
};

const surveyConfig: SurveyConfig = Platform.select({
  ios: {
    title: 'Help us improve the app',
    text: 'Please take a quick survey so we can make Astroline perfect for you!',
    btnTitle: "Let's do it",
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 0,
    maxShowCount: 2,
    intervalBetweenSessions: 3,
  },
  android: {
    title: 'Help us improve',
    text: 'Please take a quick survey so we can make perfect for you',
    btnTitle: 'Start Now!',
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 0,
    maxShowCount: 2,
    intervalBetweenSessions: 2,
  },
  web: {
    title: 'Help us improve the app',
    text: 'Please take a quick survey so we can make Astroline perfect for you!',
    btnTitle: "Let's do it",
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 0,
    maxShowCount: 2,
    intervalBetweenSessions: 3,
  },
}) as SurveyConfig;

const ads: Ads = {
  bannersEnabled: false,
  interEnabled: false,
};

const rescan: Rescan = {
  enabled: true,
  timeout: 1, // in days
  whoseHandPopupEnabled: true,
};

const pushConfig: PushConfig = {
  afterUse: PUSH_BEHAVIOR.NONE,
  dailyHoroscope: PUSH_BEHAVIOR.ALL,
  other: PUSH_BEHAVIOR.FREE,
};

const webOnetimeProductsDetails: WebOnetimeProduct[] = [
  {
    product: WEB_ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION,
    title: '',
    priceText: '',
    amount: 0.99,
    currency: 'usd',
    textLineThrough: '',
  },
];

const chatTimerTooltip: ChatTooltipConfig = { title: '', subtitle: '', buttonTitle: '', timer: 30, enabled: true };

const astroeventModalConfig: AstroeventModalConfig = {
  enable: false,
  timeout: 3,
  noChatShowSession: 2,
  noChatCloseRepeat: 3,
  hasChatShowSession: 3,
  hasChatCloseRepeat: 2,
  hasChatResetShow: 1,
};

export const rateUs: RateUs = {
  enabled: true,
  isCustom: false,
  showInSettings: true,
  likesCountToShow: 1,
  onboardingStep: '',
  sessionStart: 5, // For rate us after use feature
  icons: 'smiles', // smiles | stars
  nativeDelay: Platform.select({
    ios: 7,
    android: 0,
    web: 0,
  }) as number,
  minRateToTrustPilot: 3,
  showOnHomeDelay: 5, // seconds
  showOnHomeSession: 1,
  titleText: '',
};

const defaultParams: DefaultParams = {
  isHoroscopeRandom: false,
  isNotificationBadgeVisible: false,
  pushConfig,
  isOnboarding: true,
  fbAEMenabled: true,
  surveyConfig,
  ads,
  authZendescToken,
  contactUsMethod: CONTACT_US_METHODS.HELPDESK,
  helpdeskConfig: {
    agentID: '',
    teamID: HELPDESK_TEAM_ID,
    tagIDs: [],
  },
  isDevScreenEnabled: false,
  onboardingLoadingDuration: 10000,
  onboardingLoadingAutoSkipDelay: -1,
  onbPalmSkipBtnPlace: SKIP_BUTTON_PLACE.BOTTOM,
  whereNavigateAfterOnboarding: '',
  showUpdateButtonOnYourDayWidget: Platform.select({
    ios: true,
    android: false,
  }) as boolean,
  showCancelSubscriptionBtn: true,
  familyGuideEnabled: Platform.select({
    ios: true,
    android: false,
  }) as boolean,
  familyGuideLink: Platform.select({
    ios: 'https://astroline.go.link/p?adj_t=ddb0rg2&invite=family_share',
    android: '',
  }) as string,
  subAfterBackgroundSessionStart: Platform.select({
    ios: 0,
    android: 3,
  }) as number,
  availableSignInWithEmail: true,
  availableSignInSocial: false,
  voiceCommandSuggestionMaxSeenTimes: Platform.select({
    ios: 100,
    android: 0,
  }) as number,
  voiceCommandSuggestionDisplayTimeout: Platform.select({
    ios: 4800000,
    android: 0,
  }) as number,
  tabBarBadges: {
    [ROUTES.DAILY]: NUMBER,
    [ROUTES.BIRTH_CHART]: NONE,
    [ROUTES.DIVINATIONS]: NONE,
    [ROUTES.COMPATIBILITY_DASHBOARD]: NONE,
    [ROUTES.GUIDES]: NONE,
  },
  premiumIcon: Platform.select({
    ios: true,
    android: false,
  }) as boolean,
  todayWidgets: Platform.select({
    ios: IOS_WIDGETS_LIST,
    android: ANDROID_WIDGETS_LIST,
    web: WEB_WIDGETS_LIST,
  }) as string[],
  readingsWidgets: Platform.select({
    ios: [READING_PAGE_WIDGETS.PALMISTRY],
    android: [READING_PAGE_WIDGETS.PALMISTRY, READING_PAGE_WIDGETS.MAGIC_BALL],
    web: [READING_PAGE_WIDGETS.PALMISTRY, READING_PAGE_WIDGETS.MAGIC_BALL],
  }) as string[],
  cardOfDayWidgetBtnText: '',
  summaryWidgetConfig: {
    birthChartBlockEnabled: true,
    tagsEnabled: true,
    maxResponseWaitingTime: 20,
    enableHoroscopesV2: {
      day: true,
      week: true,
      month: true,
      year: true,
    },
    pid: {
      day: 1,
      week: 1,
      month: 1,
      year: 1,
    },
  },
  compatibilityWidgets: COMPATIBILITY_WIDGETS_LIST,
  randomWidgetsSequence: false,
  guidesBanners: {
    [GUIDES_BANNERS.ZODIAC_COMPATIBILITY]: true,
    [GUIDES_BANNERS.BIRTH_CHART]: true,
    [GUIDES_BANNERS.BIORHYTHMS]: true,
    [GUIDES_BANNERS.DATING]: true,
    [GUIDES_BANNERS.MARRIAGE_COMPATIBILITY]: true,
    [GUIDES_BANNERS.FIND_TRUE_LOVE]: true,
    [GUIDES_BANNERS.PALM_READING]: true,
  },
  randomGuidesSequence: true,
  guideQuestionnaire: false,
  guideStyle: 'row',
  rateUs,
  sessionInterval: 0,
  sessionsType: 'bot',
  unlockAllSessions: false,
  modalCloseType: MODAL_CLOSE_TYPES.CROSS,
  storyDurationTime: 15000,
  prdReportsTimeout: 1, //minutes
  prdInstructionEnabled: true,
  prdDetectLinesEnabled: true,
  prdDetectionEnabled: true,
  prdPointsAnimationDuration: 1500,
  feedbackUrl: Platform.select({
    ios: 'https://docs.google.com/forms/d/e/1FAIpQLSde7jEQEZG3D44aASfqNgYHS24lGolla9FBnQ0gpXMLrGX_Qg/viewform',
    android: '',
  }) as string,
  feedbackSession: 20000,
  defaultSubscriptionScreen: SUB_SCREENS.VERTICAL_ONE, // in default set video on both platforms
  astrologersProduct: Platform.select({
    ios: PRODUCTS.LIFETIME,
    android: '',
  }) as string,
  supportEmail,
  policyLink: Platform.select({
    ios: 'https://appsella.b-cdn.net/Appsella%20Privacy%20Policy.html',
    android: 'https://agroapps.b-cdn.net/Agroapps%20Policy.html',
  }) as string,
  termsLink: Platform.select({
    ios: 'https://appsella.b-cdn.net/Appsella%20General%20Terms.html',
    android: 'https://agroapps.b-cdn.net/Agroapps%20General%20Terms.html',
  }) as string,

  onboardingFlow: Platform.select({
    ios: [
      ONBOARDING_IDS.WELCOME,
      ONBOARDING_IDS.NAME,
      ONBOARDING_IDS.GENDER,
      ONBOARDING_IDS.DATE_OF_BIRTH,
      ONBOARDING_IDS.RELATIONSHIP,
      ONBOARDING_IDS.PROCESSING,
      ONBOARDING_IDS.SUB_FAMILY,
    ],
    android: [ONBOARDING_IDS.NAME, ONBOARDING_IDS.DATE_OF_BIRTH, ONBOARDING_IDS.PROCESSING, ONBOARDING_IDS.SUB_VERTICAL_ONE],
    web: [
      ONBOARDING_IDS.WELCOME,
      ONBOARDING_IDS.NAME,
      ONBOARDING_IDS.GENDER,
      ONBOARDING_IDS.DATE_OF_BIRTH,
      ONBOARDING_IDS.RELATIONSHIP,
      ONBOARDING_IDS.PROCESSING,
    ],
  }) as string[],
  advisorsOnboardingFlow: [
    ADVISORS_ONB_PAGES_IDS.WELCOME,
    ADVISORS_ONB_PAGES_IDS.PAIN,
    ADVISORS_ONB_PAGES_IDS.BONUS,
    ADVISORS_ONB_PAGES_IDS.SKILLS,
    ADVISORS_ONB_PAGES_IDS.QUESTIONS,
    ADVISORS_ONB_PAGES_IDS.ANALYSIS,
    ADVISORS_ONB_PAGES_IDS.INAPP,
  ],
  advisorsOnboardingInappType: ASTROLOGERS_ONBOARDING_INAPP.OFFER,
  advisorsOnboardingSkipConnection: false,
  advisorsCatalog: {
    changeAdvisorEnabled: true,
    catalogEnabled: true,
    catalogBlocks: [
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.RANDOM,
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.CHATS,
      ADVISORS_CATEGORIES.RECOMMENDED,
      ADVISORS_CATEGORIES.LOVE,
      ADVISORS_CATEGORIES.ACCURATE,
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.ALL,
    ],
  },
  advisorsPid: 1,
  [SUB_TYPES.VERTICAL]: Platform.select({
    ios: verticalProductsSubscriptionDefaultParamsIos,
    android: verticalProductsSubscriptionDefaultParamsAndroid,
    default: verticalProductsSubscriptionDefaultParamsIos,
  }),

  [SUB_TYPES.FAMILY]: familySubscriptionDefaultParams,
  [SUB_TYPES.PALM]: palmSubscriptionParams,
  [SUB_TYPES.POST]: postSubscriptionParams,
  [SUB_TYPES.VERTICAL_ONE]: verticalOneProductSubscriptionParams,

  // promo offers for user reactivation
  [SUB_TYPES.PROMO_OFFER]: subscriptionPromoOffer,
  promoOffers: {
    enabled: true,
    showLimitPerSession: 3,
    showSessionsLimit: 7,
  },

  subscriptionTimer,
  subscriptionSpecialOffer,

  monetizationConfig: Platform.select({
    ios: monetizationConfigIos,
    android: monetizationConfigAndroid,
    web: monetizationConfigWeb,
  }) as MonetizationConfig,
  subscriptions,
  products,
  rescan,

  // not found in remote

  remoteNotificationsEnabled: true,
  notificationsOnStart: true,
  notificationsOnDashboardSession: 0,
  // end

  //pwa
  notificationCenter: {
    replaceTimeout: 5,
    pwaInstructions: {
      enabled: true,
      title: '',
      sessionStartNumber: 2,
      sessionNumber: 7,
    },
    pdfReports: {
      enabled: true,
      title: '',
      text: '',
    },
    whatsapp: {
      enabled: true,
      title: '',
      text: '',
      sessionNumber: 2,
      maxShowCount: 3,
    },
    messengers: {
      enabled: true,
      title: '',
      text: '',
      sessionNumber: 2,
      maxShowCount: 3,
    },
    closeBtnSessionStartNumber: 3,
  },
  pwaInstructionsV2: {
    gifIconEnabled: true,
    sessionStartNumber: 2,
    showSessionsLimit: 2,
    timeout: 5,
    closeBtnTimeout: 5,
    title: '',
    subtitle: '',
  },
  astrologistConfig: {
    connectionTimeout: 3,
    connectionCloseTimeout: 0,
    connectionButtonTitle: '',
    reconnectionTimeout: 5,
    animated: false,
    freeMinutes: 3,
    freeMinutesForFreeUsers: 0,
    enableAstrologistReview: true,
    pingTimeout: 15,
    title: '',
    btnTitle: '',
    blurMessageEnabled: false,
    skipConnection: false,
  },
  astrologistNotificationConfig: {
    enabled: true,
    categories: [ADVISOR_NOTIFICATIONS.CONTEXT, ADVISOR_NOTIFICATIONS.TRANSIT, ADVISOR_NOTIFICATIONS.RELATIONSHIP, ADVISOR_NOTIFICATIONS.GENERAL],
    daysOfRandomMessages: 3,
  },
  reactivateCloseButton: {
    link: '',
    title: '',
  },
  pdfReportsModalConfig: {
    title: '',
    text: '',
    btnTitle: '',
    timeout: 30,
    sessionNumber: 2,
    showSessionsLimit: 2,
  },
  webOnetimeProductsDetails,
  isSkipAuthOnPressHiddenButtonEnabled: false,
  birthChartAvailableForInAppFromDate: '',
  birthChartTransitsTitle: '',
  birthChartBannerTargetTab: TOP_TABS_IDS.TRANSITS,
  chatTimerTooltip,
  astrologersMonetizationConfig,
  astrologersSecondsLeftToEnableTriggers: 180,
  astrologerChatBtnTitle: '',
  astrologerChatDisclaimer: {
    enabled: false,
    expanded: false,
  },
  astroeventModalConfig,
  astrologerFreeMinutesForRenew: 3,
  astrologerFreeMinutesModalBtnTitle: '',
  astrologerFreeMinutesModalSession: 4,
  minRateToTrustPilot: 3,
  astrologersPromoConfig: {
    places: {
      [ASTROLOGERS_PROMO_PLACES.DAILY_TIPS]: true,
      [ASTROLOGERS_PROMO_PLACES.COMPATIBILITY]: true,
      [ASTROLOGERS_PROMO_PLACES.TRANSIT]: true,
      [ASTROLOGERS_PROMO_PLACES.LUCKY_COLOR]: true,
      [ASTROLOGERS_PROMO_PLACES.LUCKY_NUMBER]: true,
      [ASTROLOGERS_PROMO_PLACES.LUCKY_TIME]: true,
      [ASTROLOGERS_PROMO_PLACES.BIORHYTHM]: true,
      [ASTROLOGERS_PROMO_PLACES.LUNAR_CALENDAR]: true,
      [ASTROLOGERS_PROMO_PLACES.TAROT]: true,
      [ASTROLOGERS_PROMO_PLACES.HOUSES]: true,
      [ASTROLOGERS_PROMO_PLACES.PLANETS]: true,
      [ASTROLOGERS_PROMO_PLACES.PALM_READING]: true,
    },
    buttonTitle: '',
  },
  messengersModalConfig: {
    enabled: false,
    buttons: {
      whatsApp: false,
      email: false,
    },
    title: '',
    buttonTitle: '',
  },
  isNewYearBgEnabled: false,
  showPlansButton: false,
  removeIdfmFromUrl: false,
  todayMatchesBlockV2Enabled: false,
  autoRefillModalConfig: {
    refillTimer: 5,
    enableCloseButton: true,
  },
  enableAdvisorPalmReadingFirstSession: true,
  astrologerConsultationPacks,
  astrologerPackSpecialOffer,
  astrologerPackWelcomeOffer,
  astrologerPackSpecialOffer2,
  astrologerPacksFlows,
  isOneClickEnabled: true,
  astrologerCreditsConfig,
  enableAdvisorsSuggests: true,
  tarotCardOfTheDayCalendarEnabled: true,
  astrologistBackendConfig: {
    er: true,
    sendPayments: true,
    palmReadingEnabled: true,
    symbolsPerSecond: 5,
    maxTimeOfDelay: 30,
  },
  receiveTarot: true,
  astroCalendarConfig: defaultAstroCalendarConfig,
  astroCalendarNotificationConfig: defaultAstroCalendarNotificationConfig,
  astroCalendarTutorialEnabled: true,
  astroCalendarWidgetTitle: '',
  astroCalendarWidgetButtonTitle: '',
  astroCalendarCompleteFirstTodoEnabled: true,
  tallyQuestionnaireConfig: {
    enabled: true,
    link: '',
    showCloseButton: true,
  },
  advisorReviewTipsConfig: {
    enabled: true,
    availablePaymentMethods: Platform.select({
      ios: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.PAYPAL],
      android: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.PAYPAL],
    }) as PAYMENT_METHODS[],
    products: {
      [WEB_TIP_PRODUCTS.ASTROLOGER_TIP_1]: {
        price: 1,
        activePrice: '$1',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '€1',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      [WEB_TIP_PRODUCTS.ASTROLOGER_TIP_3]: {
        price: 3,
        activePrice: '$3',
        currency: 'USD',
        EUR: {
          price: 3,
          activePrice: '€3',
        },
        CAD: {
          price: 3,
          activePrice: '',
        },
        AUD: {
          price: 3,
          activePrice: '',
        },
      },
      [WEB_TIP_PRODUCTS.ASTROLOGER_TIP_5]: {
        price: 5,
        activePrice: '$5',
        currency: 'USD',
        EUR: {
          price: 5,
          activePrice: '€5',
        },
        CAD: {
          price: 5,
          activePrice: '',
        },
        AUD: {
          price: 5,
          activePrice: '',
        },
      },
      [WEB_TIP_PRODUCTS.ASTROLOGER_TIP_10]: {
        price: 10,
        activePrice: '$10',
        currency: 'USD',
        EUR: {
          price: 10,
          activePrice: '€10',
        },
        CAD: {
          price: 10,
          activePrice: '',
        },
        AUD: {
          price: 10,
          activePrice: '',
        },
      },
    },
  },
  installMobileNotification: {
    promocode: 'rnw_to_app',
    enabled: true,
    closeEnabled: true,
    purchasesCount: 1,
  },
  installMobileAppModalConfig: {
    closeBtnTimeout: 5,
    subtitle: '',
    title: '',
    showSessionLimits: 10,
    enabled: true,
    promocode: 'rnw_to_app',
  },
  appLink: 'https://astroline.go.link?adj_t=kc97xjm',
  loyaltyCriteria: {
    purchases: 1,
    advisorRating: 4,
    appRating: 4,
  },
  advisorsMyChatsListEnabled: true,
  advisorsServicesConfig: {
    enabled: true,
    showPricesInList: true,
    showPricesInBtn: true,
    navigateAfterEndChatEnabled: true,
    products: {
      astrologer_service_1: {
        price: 4.99,
        activePrice: '$4.99',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_3: {
        price: 4.99,
        activePrice: '$4.99',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_5: {
        price: 4.99,
        activePrice: '$4.99',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_7: {
        price: 4.99,
        activePrice: '$4.99',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_8: {
        price: 199,
        activePrice: '$199',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_9: {
        price: 199,
        activePrice: '$199',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
      astrologer_service_10: {
        price: 199,
        activePrice: '$199',
        currency: 'USD',
        EUR: {
          price: 1,
          activePrice: '',
        },
        CAD: {
          price: 1,
          activePrice: '',
        },
        AUD: {
          price: 1,
          activePrice: '',
        },
      },
    },
  },
  subBenefitsConfig: {
    enabled: true,
    showOnManageSub: true,
    timeout: 15,
    dailyMinutes: 10,
    percentsOff: 50,
    sessionNumber: 1,
    showSessionsLimit: 2,
  },
  paymentFailedTriggerNotificationEnabled: true,
  todayContentTipsEnabled: false,
  todayContentDoDontEnabled: false,
  todayContentPid: {
    horoscopesTips: 1,
    horoscopesDoDont: 1,
    horoscopesTipsDoDont: 1,
  },
  whereNavigateAfterOnboardingPWA: '',
  sendAdvisorTime: true,
  localizations: {
    ar: {},
    de: {},
    en: {},
    es: {},
    fr: {},
    pt: {},
    ja: {},
  },
  enableVoiceMessages: true,
  trustPilotPromoConfig: {
    enabled: true,
    promocode: '',
    title: '',
    btnTitle: '',
  },
  enableIncreasingTimer: true,
  useFirstSessionTemplate: FIRST_SESSION_TEMPLATES.PALM_READING,
  showCrossPlatformRateUs: false,
};

export const getDefaultParams = () => {
  return defaultParams;
};

export default defaultParams;
