import { WEB_ONE_TIME_PRODUCT_CODES, WEB_REPORTS_PRODUCTS } from 'constants/billing';

type ReportProducts = {
  [key in Exclude<WEB_ONE_TIME_PRODUCT_CODES, WEB_ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION>]: WEB_REPORTS_PRODUCTS[];
};

export const WEB_ULTRA_REPORTS_PRODUCTS = [WEB_REPORTS_PRODUCTS.NUMEROLOGY_ANALYSIS, WEB_REPORTS_PRODUCTS.PALMISTRY_GUIDE];
export const WEB_ULTRA_2_REPORTS_PRODUCTS = [WEB_REPORTS_PRODUCTS.BIRTH_CHART, WEB_REPORTS_PRODUCTS.COMPATIBILITY];

export const WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE: ReportProducts = {
  [WEB_ONE_TIME_PRODUCT_CODES.NUMEROLOGY_ANALYSIS]: [WEB_REPORTS_PRODUCTS.NUMEROLOGY_ANALYSIS],
  [WEB_ONE_TIME_PRODUCT_CODES.PALMISTRY_GUIDE]: [WEB_REPORTS_PRODUCTS.PALMISTRY_GUIDE],
  [WEB_ONE_TIME_PRODUCT_CODES.ULTRA]: WEB_ULTRA_REPORTS_PRODUCTS,
  [WEB_ONE_TIME_PRODUCT_CODES.BIRTH_CHART]: [WEB_REPORTS_PRODUCTS.BIRTH_CHART],
  [WEB_ONE_TIME_PRODUCT_CODES.COMPATIBILITY]: [WEB_REPORTS_PRODUCTS.COMPATIBILITY],
  [WEB_ONE_TIME_PRODUCT_CODES.ULTRA_2]: WEB_ULTRA_2_REPORTS_PRODUCTS,
};
