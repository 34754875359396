import api from '..';

import { DAILY_REWARDS, DailyRewards } from './constants';

const dailyRewards = async (time: number): Promise<DailyRewards> => {
  try {
    const response = await api.fetch(DAILY_REWARDS, {
      method: 'POST',
      data: {
        time,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Daily Rewards]', error);
    return { balance: 0, freeBalance: 0 };
  }
};

export default dailyRewards;
