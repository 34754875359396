import { getISODate } from 'utils/date';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  compatibilityInsights: null,
  isWidgetVisible: true,
  loveHoroscopesActiveId: null,
  loveHoroscopesUpdateDate: null,
  loveHoroscopesPlan: [],
  onboardingFlow: [],
  onboardingIsCompleted: false,
  onboardingStep: null,
  partnerData: {
    name: null,
    date: null,
    place: null,
    time: null,
  },
  result: null,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_WIDGET_VISIBLE:
      return {
        ...state,
        isWidgetVisible: false,
      };

    case TYPES.SET_ONBOARDING_FLOW:
      return {
        ...state,
        onboardingStep: action.payload[0],
        onboardingFlow: action.payload,
      };

    case TYPES.SET_ONBOARDING_STEP:
      return {
        ...state,
        onboardingStep: action.payload,
      };

    case TYPES.SET_ONBOARDING_IS_COMPLETED:
      return {
        ...state,
        onboardingIsCompleted: true,
      };

    case TYPES.SET_RESULT:
      return {
        ...state,
        result: action.payload,
      };

    case TYPES.SET_COMPATIBILITY_INSIGHTS:
      return {
        ...state,
        compatibilityInsights: action.payload,
      };

    case TYPES.UPDATE_PARTNER_DATA:
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          ...action.payload,
        },
      };

    case TYPES.SET_LOVE_HOROSCOPES_PLAN:
      return {
        ...state,
        loveHoroscopesActiveId: action.payload[0],
        loveHoroscopesUpdateDate: getISODate(),
        loveHoroscopesPlan: action.payload,
      };

    case TYPES.SET_LOVE_HOROSCOPE_ACTIVE_ID:
      return {
        ...state,
        loveHoroscopesActiveId: action.payload,
        loveHoroscopesUpdateDate: getISODate(),
      };

    default:
      return state;
  }
}
