import { Platform } from 'react-native';

import { PaymentMethods } from 'api/purchases/interfaces';

import { BUNDLE_ID } from './general';

export const KEY = Platform.select({
  ios: 'dffda82599a341fe83a899d135928763',
  android:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlVGJ8GVVvyftaiENcEbVXBsugHdiOnvoXphRmTLgQ2gaLydCFi9aYqpHVv/V3fE61iPdmlN6BLmbdTNzDIrQP2Ql/rJJ/PmBKWtqydH9PqesspnEiuGfY51uveIH4ff7DmjMXdoNabOUC1phpRCUfhmGZQKfpTynLc//cCHuurxpT3cc4s/4IrcAuMlvgWhoBrThxRkQMK5+kmrwanyRNhYdZYV4ivt/LGs3N1C0jYeygOigvczAEc4MNuSBXlY1lRKz+Rov0tnEcaY4ivMVC+4s4PQ2rMigR/OI4r5V53U9dBSDJdnv6iybAcywEUsOte63kA+AIOw9OvyZU3v3cQIDAQAB',
});

export const FAMILY_POSTFIX = '.fm';
export const SKU = BUNDLE_ID + '.premium';
export const PRO = BUNDLE_ID + '.pro';
export const OFFER = BUNDLE_ID + '.promotional.offer';

export const WEEK = SKU + '.w.a';
export const WEEK_A = SKU + '.weekly.a';
export const WEEK_G = SKU + '.w.g';
export const WEEK_N = SKU + '.w.n';
export const WEEK_H = SKU + '.w.h';
export const WEEK_F = SKU + '.w.f';
export const WEEK_E = SKU + '.w.e';
export const WEEK_L = SKU + '.w.l';
export const MONTH = SKU + '.m.a';
export const MONTH_C = SKU + '.m.c';
export const MONTH_D = SKU + '.m.d';
export const MONTH_F = SKU + '.m.f';
export const YEAR = SKU + '.y.a';
export const YEAR_E = SKU + '.y.e';
export const YEAR_F = SKU + '.y.f';
export const YEAR_D = SKU + '.y.d';
export const YEAR_C = SKU + '.y.c';
export const YEAR_H = SKU + '.q.b';
export const FAMILY_WEEK = SKU + `.w${FAMILY_POSTFIX}.a`;
export const FAMILY_MONTH = SKU + `.m${FAMILY_POSTFIX}.a`;
export const FAMILY_YEAR = SKU + `.y${FAMILY_POSTFIX}.a`;
export const LIFETIME = SKU + '.lifetime.c';
export const PRO_W = SKU + '.w.pro';

export const OFFERS = {
  T: OFFER + '.t',
  T_A: OFFER + '.t.a',
  T_B: OFFER + '.t.b',
};

// com.horoastroline.premium.w.pro

export const OFFERS_BY_PRODUCT = {
  PRO_W: [OFFERS.T, OFFERS.T_A, OFFERS.T_B],
};

export const FAMILY_SUBS = Platform.OS === 'ios' ? [FAMILY_WEEK, FAMILY_MONTH, FAMILY_YEAR] : [];

export const SUBSCRIPTION_IDS = [
  WEEK,
  WEEK_G,
  WEEK_A,
  WEEK_N,
  WEEK_H,
  WEEK_F,
  WEEK_E,
  WEEK_L,
  MONTH,
  MONTH_C,
  MONTH_D,
  MONTH_F,
  YEAR_E,
  YEAR_D,
  YEAR_C,
  YEAR_H,
  YEAR_F,
  PRO_W,
  ...FAMILY_SUBS,
];

export const PRODUCT_IDS = [LIFETIME];

export enum PAYMENT_METHODS {
  CARD = 'card',
  APPLE_PAY = 'apple-pay',
  GOOGLE_PAY = 'google-pay',
  PAYPAL = 'paypal',
}

export const PAYMENT_SYSTEM_PROJECT = Platform.select({
  ios: 'apple_pay_h2h',
  android: 'android',
  web: process.env.REACT_APP_PAYMENT_SYSTEM_PROJECT,
});

export type Currency = 'USD' | 'AUD' | 'CAD' | 'EUR';

export enum WEB_PAYMENT_SERVICES {
  SOLIDGATE = 'solidgate',
  RECURLY = 'recurly',
  STRIPE = 'stripe',
  PADDLE = 'paddle',
  PAYPAL = 'paypal',
}

export enum WEB_POST_MESSAGE_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
  SKIPED = 'skiped',
  CLOSED = 'closed',
  BACK = 'back',
  LOADED = 'loaded',
}

export enum WEB_SUBSCRIPTION_STATE {
  ACTIVE = 'active',
  CANCELED = 'canceled', // recurly
  CANCELLED = 'cancelled', // solid
  SUSPENDED = 'SUSPENDED',
  REDEMPTION = 'redemption',
  DELETED = 'deleted',
  PROCESSED = 'processed',
  PAUSED = 'paused',
  PAST_DUE = 'past_due',
}

export enum WEB_SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  REDEMPTION = 'redemption',
}

export enum WEB_REPORTS_PRODUCTS {
  NUMEROLOGY_ANALYSIS = 'numerology_analysis',
  PALMISTRY_GUIDE = 'palmistry_guide',
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
}

export enum WEB_OTHER_PRODUCTS {
  ASTROLOGER_CONSULTATION_60 = 'astrologer_consultation_60',
  ASTROLOGER_CONSULTATION_600 = 'astrologer_consultation_600',
  ASTROLOGER_CONSULTATION_180 = 'astrologer_consultation_1800',
  ASTROLOGER_CONSULTATION_3600 = 'astrologer_consultation_3600',
}

export enum WEB_TIP_PRODUCTS {
  ASTROLOGER_TIP_1 = 'astrologer_tip_1',
  ASTROLOGER_TIP_3 = 'astrologer_tip_3',
  ASTROLOGER_TIP_5 = 'astrologer_tip_5',
  ASTROLOGER_TIP_10 = 'astrologer_tip_10',
}

export type WebProducts = WEB_REPORTS_PRODUCTS | WEB_OTHER_PRODUCTS;

export enum WEB_ONE_TIME_PRODUCT_CODES {
  NUMEROLOGY_ANALYSIS = 'numerology_analysis',
  PALMISTRY_GUIDE = 'palmistry_guide',
  ULTRA = 'numerology_analysis+palmistry_guide',
  ASTROLOGER_CONSULTATION = 'astrologer_consultation',
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
  ULTRA_2 = 'birth_chart+compatibility',
}

export const AVAILABLE_ONECLICK_METHODS = [PaymentMethods.CARD, PaymentMethods.PAYPAL, PaymentMethods.APPLE_PAY, PaymentMethods.GOOGLE_PAY];

export const ONECLICK_METHOD = '1click';
