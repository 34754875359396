import ephemeris from 'ephemeris';
import dayjs from 'dayjs';
import {
  AstrologyService,
  AspectService,
  EphemerisJSONRepository,
  OrbJSONRepository,
  TrigonometricUtilities,
  HouseSystemFactory,
  TimeConversions,
  WorldTimezoneRepository,
  ZodiacFactory,
  GeodeticLocation,
  HouseSystemType,
  RetrogradesService,
} from '@goldenius/hades-js';
import ts from '@mapbox/timespace';
import moment from 'moment';

const SIGNS = ['aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces'];

const getSignForLongitude = lon => {
  const index = Math.ceil(lon / 30) - 1;
  return SIGNS[index >= 0 ? index : 0];
};

const processTime = time => {
  if (time.length === 5) {
    return time;
  }

  return dayjs(time).format('HH:mm');
};

export const getPlanetsInfo = (date, time, lat, lon) => {
  if (!date || !time || !lat || !lon) {
    return [];
  }
  time = processTime(time);

  const tz = ts.getFuzzyLocalTimeFromPoint(Date.now(), [lon, lat]);

  const dateWithTimeZone = moment.tz(`${date} ${time}`, tz.tz());
  const planetsInfo = ephemeris.getAllPlanets(new Date(dateWithTimeZone), lon, lat);

  const { sun, moon, mercury, venus, mars, jupiter, saturn, uranus, neptune, pluto } = planetsInfo.observed;
  return [sun, moon, mercury, venus, mars, jupiter, saturn, uranus, neptune, pluto].map(x => ({
    name: x.name,
    sign: getSignForLongitude(x.apparentLongitudeDd),
    dms30: Math.round((x.apparentLongitudeDd % 30) * 100) / 100,
    dms360: x.apparentLongitudeDd,
  }));
};

export const getHouses = (date, time, lat, lon) => {
  if (!date || !time || !lat || !lon) {
    return [];
  }
  time = processTime(time);
  const timeConversions = new TimeConversions();
  const retrogradesService = new RetrogradesService();
  const ephemerisJSONRepository = new EphemerisJSONRepository(timeConversions, retrogradesService);
  const worldTimezoneRepository = new WorldTimezoneRepository();
  const orbRepository = new OrbJSONRepository();
  const aspectService = new AspectService(orbRepository);
  const trigonometricUtilities = new TrigonometricUtilities();
  const zodiacFactory = new ZodiacFactory();
  const houseSystemFactory = new HouseSystemFactory(trigonometricUtilities, zodiacFactory);

  const astrologyService = new AstrologyService(ephemerisJSONRepository, timeConversions, worldTimezoneRepository, aspectService, houseSystemFactory);

  const location = new GeodeticLocation(lon, lat);
  const dateTime = dayjs(`${date} ${time}`);
  const tz = ts.getFuzzyLocalTimeFromPoint(Date.now(), [lon, lat]).tz();

  return astrologyService.CalculateHouseSystem(HouseSystemType.Placidus, dateTime, tz, location).map(x => ({
    house: x.House,
    sign: x.Sign.Name.toLowerCase(),
    dms30: Math.round(x.RelativeDistance * 100) / 100,
    dms360: x.FullDistance,
  }));
};
