import { LunarPrediction, MoonPhase } from 'api/lunar-calendar/interfaces';

export enum TYPES {
  SET_PREDICTIONS = 'LUNAR_CALENDAR/SET_PREDICTIONS',
  SET_WIDGET = 'LUNAR_CALENDAR/SET_WIDGET',
  SET_LOCATION = 'LUNAR_CALENDAR/SET_LOCATION',
}

export interface Location {
  accuracy_radius?: number;
  latitude?: number;
  longitude?: number;
  time_zone?: string;
}

type Widget =
  | {
      moon_phase: string;
      sign: null;
      moon_phases_dates: [];
      ritual: null;
    }
  | MoonPhase;

export interface State {
  predictions: LunarPrediction[];
  widget: Widget;
  location: Location;
}

interface SetWidget {
  type: typeof TYPES.SET_WIDGET;
  payload: MoonPhase;
}

interface SetPredictions {
  type: typeof TYPES.SET_PREDICTIONS;
  payload: LunarPrediction[];
}

interface SetLocation {
  type: typeof TYPES.SET_LOCATION;
  payload: Location;
}

export type ActionTypes = SetWidget | SetLocation | SetPredictions;
