import React, { useEffect, useState, FC } from 'react';
import { Modal, StyleProp, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native';
import type { DimensionValue } from 'react-native';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { fs, paddingVertical } from '@wowmaking/ui/src/utils';

import { RootState } from 'store';
import * as COLORS from 'constants/colors';
import Text from 'components/text';
import Iframe from 'components/web/iframe';
import { WEB_POST_MESSAGE_STATUS } from 'constants/billing';

interface Props {
  buttonTitle: string;
  link: string;
  style?: StyleProp<ViewStyle>;
  onSuccess: (data: any) => void;
  onSkip: () => void;
  onPress: () => void;
}

const RenewSubscriptionButton: FC<Props> = ({ buttonTitle, link, style, onSuccess, onSkip, onPress }) => {
  const idfm = useSelector((state: RootState) => state.auth.webUUID);
  const webMode = useSelector((state: RootState) => state.app.webMode);
  const [isIframeOpen, setIsIframeOpen] = useState(false);

  const handlePress = () => {
    setIsIframeOpen(true);
    onPress();
  };

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (['plans', 'reactivate'].includes(event?.data?.source)) {
        const status = event?.data?.status as WEB_POST_MESSAGE_STATUS;

        switch (status) {
          case WEB_POST_MESSAGE_STATUS.SUCCESS:
            if (typeof onSuccess === 'function') {
              onSuccess(event?.data);
            }
            break;
          case WEB_POST_MESSAGE_STATUS.SKIPED:
          case WEB_POST_MESSAGE_STATUS.BACK:
            if (typeof onSkip === 'function') {
              onSkip();
            }
            break;
          default:
            break;
        }

        if (status !== WEB_POST_MESSAGE_STATUS.LOADED) {
          setIsIframeOpen(false);
        }
      }
    };
    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [onSkip, onSuccess]);

  const baseUrl = window.location.hostname === 'localhost' ? process.env.REACT_APP_WEB_ASB_STAGE_URL : window.location.origin;

  const query = {
    idfm,
    mode: webMode,
  };

  const url = link
    ? queryString.stringifyUrl({
        url: `${baseUrl}/asb/${link}` as unknown as string,
        query,
      })
    : '';

  const closeModal = () => setIsIframeOpen(false);

  return (
    <>
      <TouchableOpacity style={[styles.button, style]} onPress={handlePress}>
        <Text style={styles.buttonTitle}>{buttonTitle}</Text>
      </TouchableOpacity>

      {!!url && (
        <Modal visible={isIframeOpen} style={styles.iframe_modal}>
          <Iframe id="reactivateIframe" src={url} onError={closeModal} />
        </Modal>
      )}
    </>
  );
};

export default RenewSubscriptionButton;

const styles = StyleSheet.create({
  iframe_modal: {
    width: '100vw' as DimensionValue,
    height: '100vh' as DimensionValue,
    top: 0,
    left: 0,
  },
  button: {
    marginTop: paddingVertical(10),
    paddingVertical: paddingVertical(5),
  },
  buttonTitle: {
    fontSize: fs(15),
    color: COLORS.AQUA,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
});
