import { ImageMaps } from 'interfaces/image';

import EARTH_GOLD_ICON from '../../assets/icons/planets/gold/earth.png';
import MARS_GOLD_ICON from '../../assets/icons/planets/gold/mars.png';
import VENUS_GOLD_ICON from '../../assets/icons/planets/gold/venus.png';
import MERCURY_GOLD_ICON from '../../assets/icons/planets/gold/mercury.png';
import MOON_GOLD_ICON from '../../assets/icons/planets/gold/moon.png';
import SUN_GOLD_ICON from '../../assets/icons/planets/gold/sun.png';
import PLUTO_GOLD_ICON from '../../assets/icons/planets/gold/pluto.png';
import JUPITER_GOLD_ICON from '../../assets/icons/planets/gold/jupiter.png';
import SATURN_GOLD_ICON from '../../assets/icons/planets/gold/saturn.png';
import URANUS_GOLD_ICON from '../../assets/icons/planets/gold/uranus.png';
import NEPTUNE_GOLD_ICON from '../../assets/icons/planets/gold/neptune.png';

export enum Planets {
  EARTH = 'earth',
  MARS = 'mars',
  VENUS = 'venus',
  MERCURY = 'mercury',
  MOON = 'moon',
  SUN = 'sun',
  PLUTO = 'pluto',
  JUPITER = 'jupiter',
  SATURN = 'saturn',
  URANUS = 'uranus',
  NEPTUNE = 'neptune',
}

export const MAP_PLANETS_GOLD_ICON: ImageMaps = {
  [Planets.EARTH]: EARTH_GOLD_ICON,
  [Planets.MARS]: MARS_GOLD_ICON,
  [Planets.VENUS]: VENUS_GOLD_ICON,
  [Planets.MERCURY]: MERCURY_GOLD_ICON,
  [Planets.MOON]: MOON_GOLD_ICON,
  [Planets.SUN]: SUN_GOLD_ICON,
  [Planets.PLUTO]: PLUTO_GOLD_ICON,
  [Planets.JUPITER]: JUPITER_GOLD_ICON,
  [Planets.SATURN]: SATURN_GOLD_ICON,
  [Planets.URANUS]: URANUS_GOLD_ICON,
  [Planets.NEPTUNE]: NEPTUNE_GOLD_ICON,
};
