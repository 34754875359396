import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { sw, paddingVertical, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import Button from 'components/button';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import * as ICONS from 'constants/icons';
import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import HighLightText from 'components/hight-light-text';

import BEST_IMG from '../images/best.png';

interface Props {}

const ReactivationThankYouModal: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.REACTIVATION_THX);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Web_Sub', 'Thanks_Open');
    }
  }, [isVisible]);

  const handleModalClose = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <Modal style={styles.modalContainer} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND} isVisible={isVisible} animationInTiming={500}>
      <View style={styles.modal}>
        <TouchableOpacity style={styles.closeIconWrapper} onPress={handleModalClose}>
          <Image resizeMode={'cover'} style={styles.closeIcon} source={ICONS.CLOSE_ICON} />
        </TouchableOpacity>
        <Image source={BEST_IMG} style={styles.icon} resizeMode={'cover'} />
        <Text font="Philosopher" style={styles.modalTitle}>
          {t('SUBSCRIPTION.REACTIVATION.THANK_YOU.TITLE')}
        </Text>
        <HighLightText style={styles.modalText} highlightedTextStyle={styles.highlightedText}>
          {t('SUBSCRIPTION.REACTIVATION.THANK_YOU.TEXT')}
        </HighLightText>
        <View style={styles.buttonWrap}>
          <Button
            style={styles.button}
            titleStyle={styles.buttonText}
            title={t('SUBSCRIPTION.REACTIVATION.THANK_YOU.BTN_TITLE')}
            onPress={handleModalClose}
          />
        </View>
      </View>
    </Modal>
  );
};

export default ReactivationThankYouModal;

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: '100%',
    margin: 0,
    justifyContent: 'flex-end',
  },
  modal: {
    backgroundColor: COLORS.MODAL_BG_COLOR,
    borderTopLeftRadius: sw(20),
    borderTopRightRadius: sw(20),
    paddingHorizontal: paddingHorizontal(20),
    paddingTop: paddingVertical(20),
    paddingBottom: paddingVertical(40),
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: fs(26),
    marginTop: paddingVertical(15),
    color: COLORS.BEIGE_2,
    fontWeight: '700',
    alignSelf: 'center',
    textAlign: 'center',
  },
  modalText: {
    color: COLORS.LIGHT_BEIGE,
    paddingTop: paddingVertical(10),
    fontWeight: '400',
    alignSelf: 'center',
    fontSize: fs(16),
    textAlign: 'center',
  },
  highlightedText: {
    color: COLORS.BEIGE_2,
    fontWeight: '400',
  },
  icon: {
    width: sw(140),
    height: sw(140),
  },
  buttonWrap: {
    width: '100%',
    marginTop: paddingVertical(25),
    paddingHorizontal: paddingHorizontal(10),
    alignItems: 'center',
  },
  button: {},
  buttonText: {
    fontSize: fs(20),
    lineHeight: fs(22),
    color: COLORS.WHITE,
    width: '100%',
  },
  closeIconWrapper: {
    top: paddingHorizontal(20),
    right: paddingHorizontal(20),
    position: 'absolute',
  },
  closeIcon: {
    width: sw(20),
    height: sw(20),
    tintColor: COLORS.BEIGE_2,
  },
});
