import React, { FC, useEffect, useMemo } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ImageSourcePropType, ScrollView } from 'react-native';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import { t, t2 } from 'localization';
import { AppDispatch, RootState } from 'store';
import { cancelSubscriptions } from 'store/billing/actions';
import { closeCurrentModal } from 'store/modals/actions';
import Text from 'components/text';
import Button from 'components/button';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { windowHeight } from 'constants/sizes';
import { BENEFITS, BENEFITS_TRIGGERS } from 'constants/sub-benefits';
import PRO_ICON from 'assets/icons/pro/pro.png';

import ICON_CROSS from '../images/beige-cross.png';

import STARS_IMG from './images/stars.png';
import FREE_MINUTES_IMG from './images/free-minutes.png';
import DISCOUNT_IMG from './images/discount.png';
import UNLIMITED_IMG from './images/unlimited.png';

interface Props {}

export const ICONS_MAP: { [key in BENEFITS]: ImageSourcePropType } = {
  [BENEFITS.FREE_MINUTES]: FREE_MINUTES_IMG,
  [BENEFITS.DISCOUNT]: DISCOUNT_IMG,
  [BENEFITS.UNLIMITED]: UNLIMITED_IMG,
};

const SubBenefitsModal: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.SUB_BENEFITS);
  const dailyMinutes = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.subBenefitsConfig?.dailyMinutes);
  const percentsOff = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.subBenefitsConfig?.percentsOff);
  const trigger = useSelector((state: RootState) => state.modals.params?.trigger as unknown as BENEFITS_TRIGGERS);
  const isUnsubscribe = useMemo(() => trigger === BENEFITS_TRIGGERS.MANAGE_SUBSCRIPTION, [trigger]);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Sub_Benefits', 'Modal_Open', { trigger });
    }
  }, [isVisible]);

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  const handleButtonPress = () => {
    Analytics.trackEvent('Sub_Benefits', 'Modal_Button_Click');
    dispatch(closeCurrentModal());
  };

  const handleLinkPress = () => {
    Analytics.trackEvent('Sub_Benefits', 'Modal_Link_Click');
    dispatch(cancelSubscriptions());
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainerStyle}>
        <View style={styles.top}>
          <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
          <Image source={PRO_ICON} style={styles.pro} />
        </View>

        <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
          <Image source={ICON_CROSS} />
        </TouchableOpacity>

        <Text font="Philosopher" style={styles.title}>
          {t('SUB_BENEFITS.MODAL.TITLE')}
        </Text>

        <Text style={styles.subTitle}>{t('SUB_BENEFITS.MODAL.SUB_TITLE')}</Text>

        <View style={styles.benefits}>
          {Object.keys(BENEFITS).map(key => (
            <View key={key} style={styles.benefit}>
              <Image source={ICONS_MAP[key]} style={styles.benefitIcon} />
              <View style={styles.benefitTexts}>
                <Text font="Philosopher" style={styles.benefitTitle}>
                  {t2(`SUB_BENEFITS.MODAL.ITEMS.${key}.TITLE`, { minutes: dailyMinutes, percentsOff })}
                </Text>
                <Text style={styles.benefitText}>{t2(`SUB_BENEFITS.MODAL.ITEMS.${key}.TEXT`)}</Text>
              </View>
            </View>
          ))}
        </View>

        <Button
          title={!isUnsubscribe ? t('SUB_BENEFITS.MODAL.BTN_TITLE') : t('SUB_BENEFITS.MODAL.BTN_UNSUBSCRIBE_TITLE')}
          style={styles.button}
          onPress={handleButtonPress}
        />

        {isUnsubscribe && (
          <TouchableOpacity onPress={handleLinkPress} style={styles.link}>
            <Text style={styles.linkText}>{t('SUB_BENEFITS.MODAL.BTN_UNSUBSCRIBE_LINK_TITLE')}</Text>
          </TouchableOpacity>
        )}
      </ScrollView>
    </Modal>
  );
};

export default SubBenefitsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    maxHeight: windowHeight * 0.97,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
  },
  contentContainerStyle: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(20),
    right: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    marginTop: paddingVertical(30),
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    width: sw(325),
  },
  pro: {},
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(5),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(17),
    color: COLORS.BEIGE_2,
    textAlign: 'center',
    opacity: 0.8,
  },
  benefits: {
    width: '100%',
    marginTop: paddingVertical(15),
  },
  benefit: {
    marginTop: paddingVertical(15),
    width: '100%',
    borderRadius: 15,
    backgroundColor: COLORS.DARK_GREY_BLUE,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: paddingHorizontal(10),
    paddingRight: paddingHorizontal(15),
    paddingVertical: paddingVertical(15),
  },
  benefitIcon: {
    width: 32,
    height: 32,
  },
  benefitTexts: {
    flex: 1,
    marginLeft: paddingHorizontal(10),
  },
  benefitTitle: {
    fontSize: fs(17),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
  },
  benefitText: {
    marginTop: paddingVertical(5),
    fontSize: fs(15),
    color: COLORS.BEIGE,
    opacity: 0.8,
  },
  button: {
    marginTop: paddingVertical(30),
  },
  link: {
    marginTop: paddingVertical(20),
  },
  linkText: {
    fontSize: fs(14),
    lineHeight: fs(14),
    color: COLORS.BEIGE,
    opacity: 0.8,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});
