export const MIDDLE_WEEK_INDEX = 3;

export enum POSITIONS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum PERIODS_IDS {
  BEFORE_YESTERDAY = 'BEFORE_YESTERDAY',
  TWO_DAYS_BEFORE_YESTERDAY = 'TWO_DAYS_BEFORE_YESTERDAY',
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  AFTER_TOMORROW = 'AFTER_TOMORROW',
  TWO_DAYS_AFTER_TOMORROW = 'TWO_DAYS_AFTER_TOMORROW',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  NEXT_YEAR = 'NEXT_YEAR',
}

export const PERIODS: PERIODS_IDS[] = [PERIODS_IDS.TODAY, PERIODS_IDS.TOMORROW, PERIODS_IDS.WEEK, PERIODS_IDS.MONTH, PERIODS_IDS.YEAR];

export const DAYS: PERIODS_IDS[] = [
  PERIODS_IDS.BEFORE_YESTERDAY,
  PERIODS_IDS.YESTERDAY,
  PERIODS_IDS.TODAY,
  PERIODS_IDS.TOMORROW,
  PERIODS_IDS.AFTER_TOMORROW,
];

export const TRACK_DAYS: PERIODS_IDS[] = [
  PERIODS_IDS.TWO_DAYS_BEFORE_YESTERDAY,
  PERIODS_IDS.BEFORE_YESTERDAY,
  PERIODS_IDS.YESTERDAY,
  PERIODS_IDS.TODAY,
  PERIODS_IDS.TOMORROW,
  PERIODS_IDS.AFTER_TOMORROW,
  PERIODS_IDS.TWO_DAYS_AFTER_TOMORROW,
];
