import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { getWebOneTimePurchaseCodes } from 'store/billing/selectors';
import { WEB_ONE_TIME_PRODUCT_CODES, WEB_REPORTS_PRODUCTS } from 'constants/billing';
import { WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE, WEB_ULTRA_2_REPORTS_PRODUCTS } from 'constants/upsale';

export const getAvailablePDFReportProducts = createSelector([getWebOneTimePurchaseCodes], (productCodes): WEB_REPORTS_PRODUCTS[] => {
  return productCodes.reduce(
    (products: WEB_REPORTS_PRODUCTS[], productCode: Omit<WEB_ONE_TIME_PRODUCT_CODES, WEB_ONE_TIME_PRODUCT_CODES.ASTROLOGER_CONSULTATION>) => {
      const codes = WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE[productCode as keyof typeof WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE];

      if (!isEmpty(codes)) {
        for (const code in codes) {
          if (!products?.includes?.(codes[code])) {
            products.push(codes[code] as unknown as WEB_REPORTS_PRODUCTS);
          }
        }
      }

      return products;
    },
    [],
  );
});

export const getUnavailablePDFReportProducts = createSelector([getWebOneTimePurchaseCodes], (productCodes): WEB_REPORTS_PRODUCTS[] => {
  const availableReportsProducts = productCodes.flatMap(
    productCode => WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE[productCode as keyof typeof WEB_REPORT_PRODUCTS_BY_PRODUCT_CODE] || [],
  );

  const notAvailableReportsProducts = WEB_ULTRA_2_REPORTS_PRODUCTS.filter(reportProduct => !availableReportsProducts?.includes?.(reportProduct));

  return notAvailableReportsProducts;
});
