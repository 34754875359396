export const SET_VIEWED = 'UP_SALES/SET_VIEWED';

export type OpenReportPlace = 'notification' | 'settings';

export interface State {
  isViewed: boolean;
}

interface SetViewed {
  type: typeof SET_VIEWED;
  payload: boolean;
}

export type ActionTypes = SetViewed;
