import { Planets } from 'constants/planets';
import { getISODate } from 'utils/date';
import {
  NEW_MOON,
  WAXING_CRESCENT,
  FIRST_QUARTER,
  WAXING_GIBBOUS,
  FULL_MOON,
  WANING_GIBBOUS,
  LAST_QUARTER,
  WANING_CRESCENT,
} from 'screens/calendars/constants';

import type { LunarPhaseEvent } from '../interfaces';
import TUTORIAL_MOON_ICON from '../images/tutorial-moon-icon.png';

export enum ASTRO_CALENDAR_EVENT_TYPE {
  LUNAR_PHASE = 'lunar_phase',
  TRANSIT = 'transit',
  RETROGRADE_PLANET = 'retrograde_planet',
}

export const LUNAR_PHASES = [
  NEW_MOON,
  WAXING_CRESCENT,
  FIRST_QUARTER,
  WAXING_GIBBOUS,
  FULL_MOON,
  WANING_GIBBOUS,
  LAST_QUARTER,
  WANING_CRESCENT,
] as const;

export const RETROGRADE_PLANETS = [
  Planets.MERCURY,
  Planets.VENUS,
  Planets.MARS,
  Planets.JUPITER,
  Planets.SATURN,
  Planets.URANUS,
  Planets.NEPTUNE,
  Planets.PLUTO,
] as const;

export enum ASTRO_EVENT_STATUS {
  AVAILABLE = 'available',
  PASSED = 'passed',
  FUTURE = 'future',
}

// Mock data for Astro calendar tutorial
export const TUTORIAL_EVENT_DATA: LunarPhaseEvent[] = [
  {
    type: ASTRO_CALENDAR_EVENT_TYPE.LUNAR_PHASE,
    date: getISODate(),
    phase: NEW_MOON,
    title: 'EVENT_TITLE',
    subtitle: 'EVENT_SUBTITLE',
    img: TUTORIAL_MOON_ICON,
  },
];

export const RETROGRADE_STORIES_CATEGORY_SLUG = 'retrogrades';
