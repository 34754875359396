import { Platform, Dimensions } from 'react-native';
import { HEADER_OFFSET } from '@wowmaking/ui/src/utils';

const { height, width } = Dimensions.get('window');

export const DEFAULT_HEADER_HEIGHT = Platform.OS === 'web' ? (height > 650 ? 64 : 54) : 80;
export const DEFAULT_HEADER_OFFSET = Platform.OS === 'web' ? 0 : HEADER_OFFSET;

export const IS_SMALL = height <= 667;

export const windowHeight = height;
export const windowWidth = width;
