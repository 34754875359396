export enum BENEFITS {
  FREE_MINUTES = 'FREE_MINUTES',
  DISCOUNT = 'DISCOUNT',
  UNLIMITED = 'UNLIMITED',
}

export enum BENEFITS_TRIGGERS {
  BADGE = 'badge',
  START_APP = 'start_app',
  MANAGE_SUBSCRIPTION = 'manage_subscription',
}
