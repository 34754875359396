import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  predictions: [],
  widget: {
    moon_phase: '',
    sign: null,
    moon_phases_dates: [],
    ritual: null,
  },
  location: {},
};

export default function (state = initialState, action: ActionTypes) {
  switch (action.type) {
    case TYPES.SET_PREDICTIONS:
      return {
        ...state,
        predictions: action.payload,
      };

    case TYPES.SET_WIDGET:
      return {
        ...state,
        widget: action.payload,
      };

    case TYPES.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
}
