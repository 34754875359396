import { DEFAULT_ASTROLOGIST_INFO } from 'screens/advisors/constants';
import { PROFILE_TYPES } from 'store/profile/types';
import { Astrologist } from 'api/astrology-chat/constants';

import { TYPES, ActionType, State } from './types';

const initialState: State = {
  wasRequest: false,
  questions: [],
  chatQuestions: [], // здесь добавляется сообщение погадать по руке (возможно что-то еще в будущем)
  astrologist: DEFAULT_ASTROLOGIST_INFO,
  lastConnectedAstrologer: {} as Astrologist,
  allAstrologers: [],
  lastConnectionSessionNumber: 0,
  palmReadingData: null,
  promocode: '',
  isAstrocartographyEnabled: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_REQUEST:
      return {
        ...state,
        wasRequest: action.payload,
      };
    case TYPES.SET_ASTROLOGIST:
      return {
        ...state,
        astrologist: action.payload,
      };
    case TYPES.SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
        chatQuestions: action.payload.chatQuestions,
      };
    case TYPES.SET_ASTROLOGERS:
      return {
        ...state,
        allAstrologers: action.payload,
      };
    case TYPES.SET_LAST_CONNECTION_SESSION_NUMBER:
      return {
        ...state,
        lastConnectionSessionNumber: action.payload,
      };
    case TYPES.SET_LAST_CONNECTED_ASTROLOGER:
      return {
        ...state,
        lastConnectedAstrologer: action.payload,
      };
    case TYPES.SET_ASTROLOGER_PALM_READING_DATA:
      return {
        ...state,
        palmReadingData: action.payload,
      };
    case TYPES.SET_PROMOCODE:
      return {
        ...state,
        promocode: action.payload,
      };
    case TYPES.SET_ASTROCARTOGRAPHY:
      return {
        ...state,
        isAstrocartographyEnabled: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...initialState,
        promocode: state.promocode,
      };
    default:
      return state;
  }
}
