import _ from 'lodash';

import { DefaultParams } from 'store/remote-config/interfaces';

function splitParams(paramsAsString, defaultValue) {
  if (!paramsAsString || typeof paramsAsString !== 'string') {
    return [];
  }

  const reg = /\[|\]/i;
  const result = paramsAsString.match(reg);
  if (result) {
    const configParam = JSON.parse(paramsAsString);

    if (!(configParam instanceof Array)) {
      return defaultValue;
    }

    return configParam.map((item: string) => {
      return item.toUpperCase().trim();
    });
  }

  return paramsAsString.split(',').map(param => param.toUpperCase().trim());
}

function parseJSONParams(paramsAsString, defaultValue) {
  try {
    return JSON.parse(paramsAsString);
  } catch (err) {
    return defaultValue;
  }
}

function convertType<ValueType, DefaultValue>(value: ValueType, defaultValue: DefaultValue) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  if (_.isString(defaultValue)) {
    return value || defaultValue;
  }
  if (_.isPlainObject(defaultValue)) {
    return parseJSONParams(value, defaultValue);
  }
  if (_.isArray(defaultValue)) {
    return splitParams(value, defaultValue);
  }
  if (_.isBoolean(defaultValue)) {
    return !!value;
  }
  if (_.isNumber(defaultValue)) {
    return value;
  }
  return value;
}

export function normalize(snapshot: DefaultParams | null, defaultValues: DefaultParams, enableLogger = false): DefaultParams {
  if (enableLogger && __DEV__) {
    console.log('Normalizer: param names', Object.keys(defaultValues));
    console.log('Normalizer: snapshot', snapshot);
  }

  const config = Object.keys(defaultValues).reduce<DefaultParams>((convertedData, paramName) => {
    let value = snapshot?.[paramName];
    const defaultValue = defaultValues[paramName];

    if (paramName === 'onboardingFlow' && value === null) {
      value = '';
    }

    convertedData[paramName] = convertType<typeof value, typeof defaultValue>(value, defaultValue);
    return convertedData;
  }, {} as DefaultParams);

  if (enableLogger && __DEV__) {
    console.log('Normalizer: normalized config', config);
  }

  return config;
}
