import ASCENDANT_COLOR_ICON from './images/color-ascendant.png';
import MATTE_JUPITER_ICON from './images/color-jupiter.png';
import MATTE_MARS_ICON from './images/color-mars.png';
import MATTE_MERCURY_ICON from './images/color-mercury.png';
import MATTE_MOON_ICON from './images/color-moon.png';
import MATTE_NEPTUNE_ICON from './images/color-neptune.png';
import MATTE_PLUTO_ICON from './images/color-pluto.png';
import MATTE_SATURN_ICON from './images/color-saturn.png';
import MATTE_SUN_ICON from './images/color-sun.png';
import MATTE_URANUS_ICON from './images/color-uranus.png';
import MATTE_VENUS_ICON from './images/color-venus.png';
import CONJUNCTION_ICON from './images/conjunction.png';
import OPPOSITION_ICON from './images/opposition.png';
import SEXTILE_ICON from './images/sextile.png';
import SQUARE_ICON from './images/square.png';
import TRINE_ICON from './images/trine.png';
import TRANSITS_JUPITER from './images/planets/icon-jupiter.png';
import TRANSITS_MARS from './images/planets/icon-mars.png';
import TRANSITS_MERCURY from './images/planets/icon-mercury.png';
import TRANSITS_MOON from './images/planets/icon-moon.png';
import TRANSITS_NEPTUNE from './images/planets/icon-neptune.png';
import TRANSITS_PLUTO from './images/planets/icon-pluto.png';
import TRANSITS_SATURN from './images/planets/icon-saturn.png';
import TRANSITS_SUN from './images/planets/icon-sun.png';
import TRANSITS_URANUS from './images/planets/icon-uranus.png';
import TRANSITS_VENUS from './images/planets/icon-venus.png';
import ASCENDANT_ICON from './images/icons/ascendant-icon.png';
import JUPITER_ICON from './images/icons/jupiter-icon.png';
import MARS_ICON from './images/icons/mars-icon.png';
import MERCURY_ICON from './images/icons/mercury-icon.png';
import MOON_ICON from './images/icons/moon-icon.png';
import NEPTUNE_ICON from './images/icons/neptune-icon.png';
import PLUTO_ICON from './images/icons/pluto-icon.png';
import SATURN_ICON from './images/icons/saturn-icon.png';
import SUN_ICON from './images/icons/sun-icon.png';
import URANUS_ICON from './images/icons/uranus-icon.png';
import VENUS_ICON from './images/icons/venus-icon.png';
import ASCENDANT_IMG from './images/ascendant.png';
import JUPITER_IMG from './images/jupiter.png';
import MARS_IMG from './images/mars.png';
import MERCURY_IMG from './images/mercury.png';
import MOON_IMG from './images/moon.png';
import NEPTUNE_IMG from './images/neptune.png';
import PLUTO_IMG from './images/pluto.png';
import SATURN_IMG from './images/saturn.png';
import SUN_IMG from './images/sun.png';
import URANUS_IMG from './images/uranus.png';
import VENUS_IMG from './images/venus.png';
import { PlanetImages, AspectsImages } from './interfaces';

export enum PLANET_IDS {
  ASCENDANT = 'ascendant',
  JUPITER = 'jupiter',
  MARS = 'mars',
  MERCURY = 'mercury',
  MOON = 'moon',
  NEPTUNE = 'neptune',
  PLUTO = 'pluto',
  SATURN = 'saturn',
  SUN = 'sun',
  URANUS = 'uranus',
  VENUS = 'venus',
}

export enum ASPECTS_IDS {
  CONJUNCTION = 'CONJUNCTION',
  OPPOSITION = 'OPPOSITION',
  TRINE = 'TRINE',
  SQUARE = 'SQUARE',
  QUINCUNX = 'QUINCUNX',
  QUINTILE = 'QUINTILE',
  SEMI_SEXTILE = 'SEMI-SEXTILE',
  SEMI_SQUARE = 'SEMI-SQUARE',
}

export const HARMONIOUS = 'harmonious';
export const SEMI_SEXTILE = 'semi-sextile';
export const SEXTILE = 'sextile';
export const TRINE = 'trine';
export const QUINCUNX = 'quincunx';
export const DISHARMONIOUS = 'disharmonious';
export const SEMI_SQUARE = 'semi-square';
export const SQUARE = 'square';
export const SESQUIQUADRATE = 'sesquiquadrate';
export const OPPOSITION = 'opposition';
export const CONJUNCTION = 'conjunction';

export const PERSONALITY = 'personality';
export const SEX_LOVE = 'sex_love';
export const DAILY_ROUTINE = 'daily_routine';
export const INSPIRATION_IDEAS = 'inspiration_ideas';
export const SPIRITUALITY = 'spirituality';
export const SOCIAL_INTERACTION = 'social_interaction';

export const SHORT_TERM = 'short_term';
export const LONG_TERM = 'long_term';

export const MAP_PLANET_TO_ICON: PlanetImages = {
  [PLANET_IDS.ASCENDANT]: ASCENDANT_COLOR_ICON,
  [PLANET_IDS.MARS]: MATTE_MARS_ICON,
  [PLANET_IDS.VENUS]: MATTE_VENUS_ICON,
  [PLANET_IDS.MERCURY]: MATTE_MERCURY_ICON,
  [PLANET_IDS.MOON]: MATTE_MOON_ICON,
  [PLANET_IDS.SUN]: MATTE_SUN_ICON,
  [PLANET_IDS.PLUTO]: MATTE_PLUTO_ICON,
  [PLANET_IDS.JUPITER]: MATTE_JUPITER_ICON,
  [PLANET_IDS.SATURN]: MATTE_SATURN_ICON,
  [PLANET_IDS.URANUS]: MATTE_URANUS_ICON,
  [PLANET_IDS.NEPTUNE]: MATTE_NEPTUNE_ICON,
};

export const MAP_TRANSITS_ICON: Omit<PlanetImages, PLANET_IDS.ASCENDANT> = {
  [PLANET_IDS.MARS]: TRANSITS_MARS,
  [PLANET_IDS.VENUS]: TRANSITS_VENUS,
  [PLANET_IDS.MERCURY]: TRANSITS_MERCURY,
  [PLANET_IDS.MOON]: TRANSITS_MOON,
  [PLANET_IDS.SUN]: TRANSITS_SUN,
  [PLANET_IDS.PLUTO]: TRANSITS_PLUTO,
  [PLANET_IDS.JUPITER]: TRANSITS_JUPITER,
  [PLANET_IDS.SATURN]: TRANSITS_SATURN,
  [PLANET_IDS.URANUS]: TRANSITS_URANUS,
  [PLANET_IDS.NEPTUNE]: TRANSITS_NEPTUNE,
};

export const PLANET_IMAGES: PlanetImages = {
  [PLANET_IDS.ASCENDANT]: ASCENDANT_IMG,
  [PLANET_IDS.JUPITER]: JUPITER_IMG,
  [PLANET_IDS.MARS]: MARS_IMG,
  [PLANET_IDS.MERCURY]: MERCURY_IMG,
  [PLANET_IDS.MOON]: MOON_IMG,
  [PLANET_IDS.NEPTUNE]: NEPTUNE_IMG,
  [PLANET_IDS.PLUTO]: PLUTO_IMG,
  [PLANET_IDS.SATURN]: SATURN_IMG,
  [PLANET_IDS.SUN]: SUN_IMG,
  [PLANET_IDS.URANUS]: URANUS_IMG,
  [PLANET_IDS.VENUS]: VENUS_IMG,
};

export const PLANET_ICONS: PlanetImages = {
  [PLANET_IDS.ASCENDANT]: ASCENDANT_ICON,
  [PLANET_IDS.JUPITER]: JUPITER_ICON,
  [PLANET_IDS.MARS]: MARS_ICON,
  [PLANET_IDS.MERCURY]: MERCURY_ICON,
  [PLANET_IDS.MOON]: MOON_ICON,
  [PLANET_IDS.NEPTUNE]: NEPTUNE_ICON,
  [PLANET_IDS.PLUTO]: PLUTO_ICON,
  [PLANET_IDS.SATURN]: SATURN_ICON,
  [PLANET_IDS.SUN]: SUN_ICON,
  [PLANET_IDS.URANUS]: URANUS_ICON,
  [PLANET_IDS.VENUS]: VENUS_ICON,
};

export const MAP_ASPECTS_TO_ICON: AspectsImages = {
  [SQUARE]: SQUARE_ICON,
  [SEXTILE]: SEXTILE_ICON,
  [TRINE]: TRINE_ICON,
  [OPPOSITION]: OPPOSITION_ICON,
  [CONJUNCTION]: CONJUNCTION_ICON,
};

// - temporary solution. Need to be brought to a single form.
export const MAP_ASPECTS_TO_TRANSITS = [
  { name: SQUARE, diff: 90, orbis: 5 },
  { name: SEXTILE, diff: 60, orbis: 5 },
  { name: CONJUNCTION, diff: 0, orbis: 5 },
  { name: TRINE, diff: 120, orbis: 5, extra_orbis: 7 },
  { name: OPPOSITION, diff: 180, orbis: 7 },
];

export const PLANETS_POINTS = {
  [PLANET_IDS.MARS]: 8,
  [PLANET_IDS.VENUS]: 8,
  [PLANET_IDS.MERCURY]: 8,
  [PLANET_IDS.MOON]: 10,
  [PLANET_IDS.SUN]: 10,
  [PLANET_IDS.PLUTO]: 4,
  [PLANET_IDS.JUPITER]: 6,
  [PLANET_IDS.SATURN]: 6,
  [PLANET_IDS.URANUS]: 4,
  [PLANET_IDS.NEPTUNE]: 4,
};

export const PLANETS_BY_TERM = {
  [SHORT_TERM]: [PLANET_IDS.SUN, PLANET_IDS.MOON, PLANET_IDS.MERCURY, PLANET_IDS.VENUS, PLANET_IDS.MARS],
  [LONG_TERM]: [PLANET_IDS.JUPITER, PLANET_IDS.SATURN, PLANET_IDS.URANUS, PLANET_IDS.NEPTUNE, PLANET_IDS.PLUTO],
};

export const TRANSIT_PLANETS = [
  PLANET_IDS.SUN,
  PLANET_IDS.MOON,
  PLANET_IDS.MERCURY,
  PLANET_IDS.VENUS,
  PLANET_IDS.MARS,
  PLANET_IDS.JUPITER,
  PLANET_IDS.SATURN,
  PLANET_IDS.URANUS,
  PLANET_IDS.NEPTUNE,
  PLANET_IDS.PLUTO,
];

export const CHART_MODAL_INFO = {
  TITLE: 'BIRTH_CHART.MODAL.TITLE',
  POINTS: [
    {
      id: 'DESC_0',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_0',
    },
    {
      id: 'DESC_1',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_1',
    },
    {
      id: 'DESC_2',
      text: 'BIRTH_CHART.MODAL.INFO_POINTS.DESC_2',
    },
  ],
};

export const TOP_TABS_SCREEN_OPTIONS = { swipeEnabled: false };

export enum TOP_TABS_IDS {
  TRANSITS = 'transits',
  CHARTS = 'charts',
}

export const TOP_TABS: TOP_TABS_IDS[] = [TOP_TABS_IDS.TRANSITS, TOP_TABS_IDS.CHARTS];

export const PERSONALITY_PLANETS: PLANET_IDS[] = [PLANET_IDS.SUN, PLANET_IDS.MOON, PLANET_IDS.ASCENDANT];
export const STELLAR_PLANETS: PLANET_IDS[] = [
  PLANET_IDS.MERCURY,
  PLANET_IDS.VENUS,
  PLANET_IDS.MARS,
  PLANET_IDS.JUPITER,
  PLANET_IDS.SATURN,
  PLANET_IDS.URANUS,
  PLANET_IDS.NEPTUNE,
  PLANET_IDS.PLUTO,
];

export const ASPECTS_MAJOR: ASPECTS_IDS[] = [ASPECTS_IDS.CONJUNCTION, ASPECTS_IDS.OPPOSITION, ASPECTS_IDS.TRINE, ASPECTS_IDS.SQUARE];
export const ASPECTS_MINOR: ASPECTS_IDS[] = [ASPECTS_IDS.QUINCUNX, ASPECTS_IDS.QUINTILE, ASPECTS_IDS.SEMI_SEXTILE, ASPECTS_IDS.SEMI_SQUARE];

export const MIN_BIRTH_DATE = '1920-01-01';
