import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  planets: [],
  houses: [],
  planetsContent: [],
  housesContent: [],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        planets: action.payload.planets,
        houses: action.payload.houses,
      };

    case TYPES.SET_CONTENT:
      return {
        ...state,
        planetsContent: action.payload.planetsContent,
        housesContent: action.payload.housesContent,
      };

    default:
      return state;
  }
}
