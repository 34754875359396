import { paddingHorizontal, sw, fs, paddingVertical } from '@wowmaking/ui/src/utils';
import React, { FC, PropsWithChildren } from 'react';
import { ImageSourcePropType, StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle, Image } from 'react-native';
import { useSelector } from 'react-redux';

import BG_IMAGE from 'assets/images/header-bg/header-bg.png';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as ICONS from 'constants/icons';
import { GENDER_SMALL_ICONS } from 'constants/profile';
import { back } from 'store/navigation/actions';
import { RootState } from 'store';
import * as SIZES from 'constants/sizes';

import { t } from 'localization';
import CloseButton from '../close-button';

interface Props extends PropsWithChildren {
  backIcon?: boolean;
  title?: string;
  backPress?: () => void;
  menuPress?: () => void;
  menuIcon?: boolean;
  skipButton?: boolean;
  skipPress?: () => void;
  backText?: string;
  customBgImage?: ImageSourcePropType;
  hiddenSkipButton?: boolean;
  style?: StyleProp<ViewStyle>;
  withBackground?: boolean;
  textStyle?: StyleProp<TextStyle>;
  leftContainerStyle?: StyleProp<ViewStyle>;
  rightContainerStyle?: StyleProp<ViewStyle>;
}

const Header: FC<Props> = ({
  backIcon,
  menuIcon,
  backPress,
  menuPress,
  title,
  children,
  skipButton,
  skipPress,
  backText,
  customBgImage,
  style,
  withBackground = true,
  textStyle,
  leftContainerStyle = null,
  rightContainerStyle = null,
}) => {
  const closeIcon = backIcon || backPress;

  const gender = useSelector((state: RootState) => state.profile.profileData.gender);

  const handleCloseButton = () => {
    if (backPress) {
      return backPress();
    }

    return back();
  };

  const handleMenuPress = () => {
    if (menuPress) {
      menuPress();
    }
  };

  return (
    <View style={[styles.wrap, style]}>
      <View style={styles.container}>
        {withBackground && <Image style={styles.background} source={customBgImage || BG_IMAGE} resizeMode="stretch" />}
        <View style={[styles.navigationButton, leftContainerStyle]}>
          {closeIcon && <CloseButton source={ICONS.BACK_ICON} onPress={handleCloseButton} />}
          {backText && <Text style={styles.backText}>{backText}</Text>}
          {menuIcon && <CloseButton source={GENDER_SMALL_ICONS[gender]} onPress={handleMenuPress} />}
        </View>
        <Text font="Philosopher" style={[styles.title, textStyle]}>
          {title}
        </Text>
        <View style={[styles.proContainer, rightContainerStyle]}>
          {skipButton && (
            <TouchableOpacity style={styles.skipButton} onPress={skipPress}>
              <Text style={styles.skip}>{t('SKIP')}</Text>
            </TouchableOpacity>
          )}
        </View>
        {children && <View style={styles.childrenContainer}>{children}</View>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    paddingTop: SIZES.DEFAULT_HEADER_OFFSET,
  },
  container: {
    width: '100%',
    height: SIZES.DEFAULT_HEADER_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  background: {
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
    height: '100%',
  },
  navigationButton: {
    minWidth: sw(70),
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    flexDirection: 'row',
  },
  proContainer: {
    flexDirection: 'row',
    minWidth: sw(70),
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: paddingHorizontal(15),
    zIndex: 2,
  },
  title: {
    fontWeight: '700',
    fontSize: fs(22),
    color: COLORS.BEIGE_2,
    textAlign: 'center',
    maxWidth: '70%',
    zIndex: 2,
  },
  childrenContainer: {
    marginTop: paddingVertical(10),
    zIndex: 2,
  },
  skipButton: {
    justifyContent: 'center',
  },
  skip: {
    fontSize: fs(16),
    color: COLORS.AQUA,
    fontWeight: '700',
  },
  backText: {
    fontSize: fs(17),
    lineHeight: fs(22),
    letterSpacing: -0.41,
    color: COLORS.BEIGE_2,
    opacity: 0.8,
  },
});

export default Header;
