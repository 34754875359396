import { SignTypes } from 'constants/signs';

import api from '..';

import { MoonPhase, LunarPredictions } from './interfaces';

const MOON_CALENDAR = '/moon-calendar';

export const getMoonPhase = (sign: SignTypes): Promise<MoonPhase> => {
  return api.fetch(`${MOON_CALENDAR}/widget?sign=${sign}`, {
    method: 'GET',
  });
};

export const getLunarPredictions = (sign: SignTypes): Promise<LunarPredictions> => {
  return api.fetch(`${MOON_CALENDAR}/predictions?sign=${sign}`, {
    method: 'GET',
  });
};
