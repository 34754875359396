import { PlaceValue } from 'interfaces/birth-chart';

import { PROFILE_TYPES } from '../profile/types';

export const SET_ONBOARDING_DONE = 'BIRTH-CHART/SET_ONBOARDING_DONE';
export const SET_ONBOARDING_STEP = 'BIRTH-CHART/SET_ONBOARDING_STEP';
export const SET_BIRTH_PLACE = 'BIRTH-CHART/SET_BIRTH_PLACE';
export const SET_BIRTH_TIME = 'BIRTH-CHART/SET_BIRTH_TIME';
export const SET_CURRENT_LOCATION = 'BIRTH-CHART/SET_CURRENT_LOCATION';
export const SET_HOUR_FORMAT_BIRTH_TIME = 'BIRTH-CHART/SET_HOUR_FORMAT_BIRTH_TIME';
export const SET_REFRESHED = 'BIRTH-CHART/SET_REFRESHED';
export const RESET_DATA = 'BIRTH-CHART/RESET_DATA';

export interface State {
  onboardingDone: boolean;
  birthPlace: PlaceValue;
  birthTime: string | null;
  birthHourFormatTime: string;
  needToRefreshBirthChart: boolean;
  step: number;
}

interface SetOnboardingDone {
  type: typeof SET_ONBOARDING_DONE;
}

interface SetOnboardingStep {
  type: typeof SET_ONBOARDING_STEP;
}

interface SetBirthPlace {
  type: typeof SET_BIRTH_PLACE;
  payload: PlaceValue;
}

interface SetBirthTime {
  type: typeof SET_BIRTH_TIME;
  payload: string;
}

interface SetHourFormatBirthTime {
  type: typeof SET_HOUR_FORMAT_BIRTH_TIME;
  payload: string;
}

interface SetRefreshed {
  type: typeof SET_REFRESHED;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface ResetData {
  type: typeof RESET_DATA;
}

export type ActionType =
  | SetOnboardingDone
  | SetOnboardingStep
  | SetBirthPlace
  | SetBirthTime
  | SetHourFormatBirthTime
  | SetRefreshed
  | ResetUserData
  | ResetData;
