import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { RootState } from 'store';
import { getZodiac } from 'utils/zodiac';
import { SignTypes } from 'constants/signs';
import { PlanetaryReport } from 'screens/birth-charts-compatibility/interfaces';

import { CompatibilityProfileData } from './types';
import { getSignsInPlanets } from './utils';

const getPartnerData = (state: RootState) => state.birthChartsCompatibility.partnerData;

export const getUserProfileData = (state: RootState): CompatibilityProfileData => {
  const {
    birthChart: { birthPlace, birthTime },
    profile: {
      profileData: { name, date },
    },
  } = state;
  return {
    name,
    date,
    place: birthPlace,
    time: birthTime,
  };
};

export const getPartnerZodiacSign = (state: RootState): SignTypes => {
  const {
    birthChartsCompatibility: { partnerData },
  } = state;
  return SignTypes[getZodiac(dayjs(partnerData.date).get('month') + 1, dayjs(partnerData.date).get('date'), true).toLowerCase()];
};

export const selectPlanetsData = createSelector(getUserProfileData, getPartnerData, (userData, partnerData) => {
  const { date: userDate, time: userTime, place: userPlace } = userData;
  const { date: partnerDate, time: partnerTime, place: partnerPlace } = partnerData;

  if (userDate && userTime && userPlace && partnerDate) {
    const userPlanets = getSignsInPlanets(userDate, userTime, userPlace);
    const partnerPlanets = getSignsInPlanets(partnerDate, partnerTime ?? '12:00', partnerPlace ?? userPlace);

    const planets = userPlanets.reduce((acc, userPlanet) => {
      const partnerPlanet = partnerPlanets.find(planet => planet.name === userPlanet.name);

      if (partnerPlanet) {
        acc.push({
          name: userPlanet.name.toLowerCase(),
          userSign: userPlanet.sign.toLowerCase(),
          partnerSign: partnerPlanet.sign.toLowerCase(),
        });
      }

      return acc;
    }, [] as PlanetaryReport[]);

    return planets;
  } else {
    return [];
  }
});
