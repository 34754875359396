import api from '..';

import { Location } from './interfaces';

const APPROXIMATE_LOCATION = '/approximate-location';

export const getApproximateLocation = (): Promise<Location> => {
  return api.fetch(APPROXIMATE_LOCATION, {
    method: 'GET',
  });
};
