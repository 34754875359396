import { createAction } from 'redux-actions';
import _ from 'lodash';
import dayjs from 'dayjs';

import { AppDispatch, AppGetState } from 'store';
import { getCurrentHourFormat } from 'utils/timezone';
import { initNatalCharts, initNatalChartsContent } from 'store/natal-charts/actions';

import { BIRTH_CHART_ONBOARDING_STEPS } from '../../constants/onboarding-types';
import * as ROUTES from '../../constants/routes';
import { replace } from '../navigation/actions';
import { updateProfile } from '../profile/actions';
import { generateTransits, initTransitsContent } from '../transits/actions';

import {
  SET_ONBOARDING_DONE,
  SET_BIRTH_PLACE,
  SET_BIRTH_TIME,
  SET_HOUR_FORMAT_BIRTH_TIME,
  SET_REFRESHED,
  SET_ONBOARDING_STEP,
  RESET_DATA,
} from './types';

const setOnboardingDone = createAction(SET_ONBOARDING_DONE);

export const setBirthPlace = createAction(SET_BIRTH_PLACE);
const setBirthTimeAction = createAction(SET_BIRTH_TIME);
const setHourFormatBirthTimeAction = createAction(SET_HOUR_FORMAT_BIRTH_TIME);
export const setRefreshed = createAction(SET_REFRESHED);
const setOnboardingStep = createAction(SET_ONBOARDING_STEP);
export const resetBirthChartData = createAction(RESET_DATA);

export const setBirthTime = (time: string) => async (dispatch: AppDispatch) => {
  dispatch(setBirthTimeAction(time));
  const hourFormatTime = await getCurrentHourFormat(time);
  dispatch(setHourFormatBirthTimeAction(hourFormatTime));
};

export const checkBirthChartOnboardingState = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      birthChart: { birthPlace, birthHourFormatTime, step },
    } = getState();

    if (!_.isEmpty(birthPlace) && birthHourFormatTime && !step) {
      dispatch(setOnboardingDone());
    }
  };
};

export const goToNextScreen = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      birthChart: { step },
    } = getState();
    const stepsLength = Object.keys(BIRTH_CHART_ONBOARDING_STEPS).length - 1;

    if (step >= stepsLength) {
      dispatch(setOnboardingDone());
      replace(ROUTES.BIRTH_CHART_DASHBOARD);
    } else {
      dispatch(setOnboardingStep());
    }
  };
};

export const processBirthTimeData = (date: Date) => {
  return async (dispatch: AppDispatch) => {
    await dispatch(setBirthTime(dayjs(date).format('HH:mm')));
  };
};

export const generateDataBirthChart = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await dispatch(updateProfile());
      dispatch(initNatalCharts());
      dispatch(initNatalChartsContent());
      dispatch(generateTransits());
      dispatch(initTransitsContent());
      dispatch(setRefreshed());
    } catch (error) {
      console.log('ERROR generateDataBirthChart', error);
    }
  };
};
