import { Transaction } from '@wowmaking/react-native-billing';

import { WEB_PAYMENT_SERVICES, PAYMENT_SYSTEM_PROJECT } from 'constants/billing';

import api from '..';

import {
  WebPurchases,
  WebOneTimePurchases,
  OneClickPaymentData,
  ApplePayPaymentData,
  PaymentMethods,
  GooglePayPaymentData,
  OneTimePaymentResponse,
} from './interfaces';

const PAYMENTS = '/payments';
const PURCHASE = '/purchase';
const REGISTER = '/register';
const SUBS = '/subscriptions';
const ONE_TIME = '/one-time-payment';

const TRIGGER = 'asb_packs';
const DEFAULT_PROJECT = 'quiz';

export const createApplePayOrder = ({
  amount,
  currency,
  productCode,
  description,
  metadata,
  data,
  header,
  signature,
  version,
  email,
  productTrigger,
  solidMetadata,
}: ApplePayPaymentData): Promise<OneTimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/create-by-apple-pay`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger: TRIGGER,
      product_trigger: productTrigger,
      amount,
      currency,
      product_code: productCode,
      description,
      metadata: metadata ? JSON.stringify(metadata) : '',
      data,
      header,
      signature,
      version,
      customer_email: email,
      solid_metadata: solidMetadata,
    },
  });
};

export const createGooglePayOrder = (data: GooglePayPaymentData): Promise<OneTimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/create-by-google-pay`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger: TRIGGER,
      amount: data.amount,
      currency: data.currency,
      merchant: data.merchant,
      platform: data.platform,
      signature: data.signature,
      description: data.description,
      product_trigger: data.productTrigger,
      product_code: data.productCode,
      metadata: data.metadata ? JSON.stringify(data.metadata) : '',
      customer_email: data.email,
      solid_metadata: data.solidMetadata,
      signed_message: data.signedMessage,
      protocol_version: data.protocolVersion,
    },
  });
};

export const createOneClickPayment = ({
  amount,
  currency,
  productCode,
  description,
  metadata,
  productTrigger,
  solidMetadata,
}: OneClickPaymentData): Promise<OneTimePaymentResponse> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}`, {
    method: 'POST',
    data: {
      payment_system_type: WEB_PAYMENT_SERVICES.SOLIDGATE,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      trigger: TRIGGER,
      product_trigger: productTrigger,
      amount,
      currency,
      product_code: productCode,
      description,
      metadata: metadata ? JSON.stringify(metadata) : '',
      solid_metadata: solidMetadata,
    },
  });
};

export const checkOneTimePaymentStatus = (orderId: string, project = DEFAULT_PROJECT, isPaypal = false): Promise<any> => {
  return api.fetch(`${PAYMENTS}${ONE_TIME}/callback/${project}/${WEB_PAYMENT_SERVICES.SOLIDGATE}`, {
    method: 'POST',
    data: {
      is_paypal: isPaypal,
      order_id: orderId,
    },
  });
};

export const getWebSubs = (): Promise<WebPurchases> => {
  return api
    .fetch(`${PAYMENTS}${SUBS}?search=active:1`, {
      method: 'GET',
    })
    .then(r => r.data)
    .catch(() => []);
};

export const getWebAllSubs = (): Promise<WebPurchases> => {
  return api
    .fetch(`${PAYMENTS}${SUBS}/actualized`, {
      method: 'GET',
    })
    .then(r => {
      return r.data;
    });
};

export const getWebOneTimePurchases = (): Promise<WebOneTimePurchases> => {
  return api
    .fetch(`${PAYMENTS}${ONE_TIME}`, {
      method: 'GET',
    })
    .then(r => r)
    .catch(() => []);
};

export const getPaymentMethod = (): Promise<PaymentMethods | ''> => {
  return api
    .fetch(`${PAYMENTS}${ONE_TIME}/get-payment-method`, {
      method: 'GET',
    })
    .then((r: { payment_method: PaymentMethods }) => {
      return r.payment_method;
    })
    .catch(err => {
      console.log('[ERROR GET PAYMENT METHOD]:', err);
      return '';
    });
};

export const registerSub = (data: Transaction[]): Promise<Transaction> => {
  return api.fetch(`${PURCHASE}${REGISTER}`, {
    method: 'POST',
    data: {
      data,
    },
  });
};
