import dayjs from 'dayjs';
import { Origin, Horoscope } from 'circular-natal-horoscope-js';

import { COMPATIBILITY_PLANETS } from 'screens/birth-charts-compatibility/constants';
import { PlaceValue } from 'interfaces/birth-chart';

export const getSignsInPlanets = (date: string, time: string, place: PlaceValue) => {
  const origin = new Origin({
    year: dayjs(date).year(),
    month: dayjs(date).month(),
    date: dayjs(date).date(),
    hour: +time.split(':')[0],
    minute: +time.split(':')[1],
    latitude: place.lat,
    longitude: place.lon,
  });

  const horoscope: Horoscope = new Horoscope({
    origin,
    houseSystem: 'placidus',
    zodiac: 'tropical',
    language: 'en',
  });

  const planetsData = COMPATIBILITY_PLANETS.map(planetName => {
    const planet = horoscope.CelestialBodies[planetName];

    return {
      name: planet.label,
      sign: planet.Sign?.label,
    };
  });

  return planetsData;
};
