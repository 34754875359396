import { PERIODS_IDS } from 'constants/periods';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  transitsData: {
    [PERIODS_IDS.TWO_DAYS_BEFORE_YESTERDAY]: {},
    [PERIODS_IDS.BEFORE_YESTERDAY]: {},
    [PERIODS_IDS.YESTERDAY]: {},
    [PERIODS_IDS.TODAY]: {},
  },
  transitsDescription: [],
  currentHouses: [],
  currentPlanets: [],
  currentLocation: null,
  nextShortTermTransitTime: '',
  nextLongTermTransitTime: '',
  astroeventTransit: {
    title: '',
    description: '',
    duration: '',
    connectionCounter: 0,
    transit: null,
  },
  aspectsDescription: [],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_TRANSITS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.SET_TRANSITS_DESCRIPTION:
      return {
        ...state,
        transitsDescription: action.payload,
      };

    case TYPES.SET_ASTROEVENT_TRANSITS:
      return {
        ...state,
        astroeventTransit: action.payload,
      };

    case TYPES.SET_ASPECTS_DESCRIPTION:
      return {
        ...state,
        aspectsDescription: action.payload,
      };

    case TYPES.SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };

    default:
      return state;
  }
}
