import { Astrologist } from 'api/astrology-chat/constants';

import { ResetUserData } from '../types';

interface AstrologerPalmReadingData {
  hiddenMessage: string;
  image: string;
}

export interface Question {
  text: string;
  metadata: {
    type: 'suggest' | 'palmRequestSuggest' | 'astrologersPromo' | 'userQuestion';
  };
}

export type Questions = Question[];

export interface State {
  wasRequest: boolean;
  questions: Questions;
  chatQuestions: Questions;
  astrologist: Astrologist;
  allAstrologers: Astrologist[];
  lastConnectionSessionNumber: number;
  lastConnectedAstrologer: Astrologist;
  palmReadingData: AstrologerPalmReadingData | null;
  promocode: string;
  isAstrocartographyEnabled: boolean;
}

export enum TYPES {
  SET_REQUEST = 'ASTROLOGERS/SET_REQUEST',
  SET_ASTROLOGIST = 'ASTROLOGERS/SET_ASTROLOGIST',
  SET_QUESTIONS = 'ASTROLOGERS/SET_QUESTIONS',
  SET_ASTROLOGERS = 'ASTROLOGERS/SET_ASTROLOGERS',
  SET_LAST_CONNECTION_SESSION_NUMBER = 'ASTROLOGERS/SET_LAST_CONNECTION_SESSION_NUMBER',
  SET_LAST_CONNECTED_ASTROLOGER = 'ASTROLOGERS/SET_LAST_CONNECTED_ASTROLOGER',
  SET_ASTROLOGER_PALM_READING_DATA = 'ASTROLOGERS/SET_ASTROLOGER_PALM_READING_DATA',
  SET_PROMOCODE = 'ASTROLOGERS/SET_PROMOCODE',
  SET_ASTROCARTOGRAPHY = 'ASTROLOGERS/SET_ASTROCARTOGRAPHY',
}

interface SetWasRequest {
  type: typeof TYPES.SET_REQUEST;
  payload: boolean;
}

interface SetAstrologist {
  type: typeof TYPES.SET_ASTROLOGIST;
  payload: Astrologist;
}

interface SetQuestions {
  type: typeof TYPES.SET_QUESTIONS;
  payload: {
    questions: Questions;
    chatQuestions: Questions;
  };
}

interface SetAiAstrologers {
  type: typeof TYPES.SET_ASTROLOGERS;
  payload: Astrologist[];
}

interface SetLastConnectionSessionNumber {
  type: typeof TYPES.SET_LAST_CONNECTION_SESSION_NUMBER;
  payload: number;
}

interface SetLastConnectedAstrologist {
  type: typeof TYPES.SET_LAST_CONNECTED_ASTROLOGER;
  payload: Astrologist;
}

interface SetAstrologerPalmReadingData {
  type: typeof TYPES.SET_ASTROLOGER_PALM_READING_DATA;
  payload: AstrologerPalmReadingData;
}

interface SetPromocode {
  type: typeof TYPES.SET_PROMOCODE;
  payload: string;
}

interface SetAstrocartography {
  type: typeof TYPES.SET_ASTROCARTOGRAPHY;
  payload: boolean;
}

export type ActionType =
  | SetWasRequest
  | SetAstrologist
  | SetQuestions
  | SetAiAstrologers
  | SetLastConnectionSessionNumber
  | SetLastConnectionSessionNumber
  | SetLastConnectedAstrologist
  | SetLastConnectionSessionNumber
  | ResetUserData
  | SetAstrologerPalmReadingData
  | SetPromocode
  | SetAstrocartography;
