import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import type { ISODate } from 'interfaces/date';
import { t, t2 } from 'localization';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = 1440;

interface MONTHS {
  [key: number]: string;
}

const MONTH_MAP: MONTHS = {
  [1]: 'MONTHS.JAN',
  [2]: 'MONTHS.FEB',
  [3]: 'MONTHS.MAR',
  [4]: 'MONTHS.APR',
  [5]: 'MONTHS.MAY',
  [6]: 'MONTHS.JUN',
  [7]: 'MONTHS.JUL',
  [8]: 'MONTHS.AUG',
  [9]: 'MONTHS.SEP',
  [10]: 'MONTHS.OCT',
  [11]: 'MONTHS.NOV',
  [12]: 'MONTHS.DEC',
};

export const getTimeFromMilliseconds = (millisecond: number) => {
  return moment().startOf('day').add(Math.abs(millisecond), 'millisecond').format('HH:mm:ss');
};

export const getFormattedDates = (start: string | number, end: string | number) => {
  const datesBetweenInterval = getDatesRange(start, end);

  return replaceToday(datesBetweenInterval, start);
};

export const getDatesRange = (start: string | number, end: string | number) => {
  const startPeriod = moment().subtract(start, 'day');
  const endPeriod = moment().add(end, 'day');

  return getDatesBetweenInterval(startPeriod, endPeriod, 'day');
};

export const getStoryTime = (minutes: number | string) => {
  if (!_.isNumber(minutes)) {
    return '';
  }

  if (minutes >= MINUTES_PER_HOUR) {
    return `${Math.floor(minutes / MINUTES_PER_HOUR)}h`;
  }

  if (minutes >= MINUTES_PER_DAY) {
    return `${Math.floor(minutes / MINUTES_PER_DAY)}d`;
  }

  return `${minutes}m`;
};

export const getDatesBetweenInterval = (start: string | number | any, end: string | number | any, interval: any) => {
  const dates: [string, string][] = [];

  while (moment(end).diff(moment(start), interval, true) >= 0) {
    const name = moment(start).endOf(interval).format('ddd');
    const value = moment(start).endOf(interval).format('DD');

    dates.push([name, value]);
    start = moment(start).add(1, interval);
  }

  return dates;
};

const replaceToday = (dates: any, todayIndex: any) => {
  const today = t('TIME_PERIODS.TODAY');

  return dates.map((day: any, index: number) => {
    if (index === todayIndex) {
      day.splice(0, 1, today);
    }

    return day;
  });
};

export const getStringMonthByNumber = (month: string | number) => t2(MONTH_MAP[+month]);

export const getNextMidnight = () => {
  const nextDay = moment().add(1, 'days');
  return nextDay.startOf('day').format('');
};

export const getDiffMinutesFromNow = (date: string | number): number => {
  return moment().diff(moment(date), 'minutes');
};

export const getDiffDaysFromNow = (date: string | number): number => {
  return moment().diff(moment(date), 'days');
};

export const getISODate = (date: Date | Dayjs | string = dayjs()): ISODate => {
  if (typeof date === 'string' && isISODate(date)) {
    return date;
  }

  if (typeof date === 'string' && dayjs(date).isValid()) {
    return dayjs(date).format(ISO_DATE_FORMAT) as ISODate;
  }

  if (date instanceof Date) {
    return dayjs(date).format(ISO_DATE_FORMAT) as ISODate;
  }

  if (date instanceof dayjs && date.isValid()) {
    return date.format(ISO_DATE_FORMAT) as ISODate;
  }

  return dayjs().format(ISO_DATE_FORMAT) as ISODate;
};

export const isISODate = (date: string): date is ISODate => {
  return typeof date === 'string' && /^\d{4}-[0-1]\d-[0-3]\d$/.test(date);
};

export const getISODatesRange = (start: ISODate, end: ISODate): ISODate[] => {
  const dates: ISODate[] = [];
  let currentDate = dayjs(start);

  while (currentDate.isSameOrBefore(end)) {
    dates.push(getISODate(currentDate));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const getStartEndFromDatesRange = (dates: ISODate[]): { start: ISODate; end: ISODate } => {
  const sortedDates = [...dates].sort();

  return {
    start: sortedDates[0],
    end: sortedDates[sortedDates.length - 1],
  };
};
