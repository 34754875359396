import { NatalChartsPlanet, NatalChartsHouse } from 'api/natal-charts/interfaces';
import { SignTypes } from 'constants/signs';
import { PLANET_IDS } from 'screens/birth-chart/constants';

export enum TYPES {
  SET = 'NATAL_CHARTS/SET',
  SET_CONTENT = 'NATAL_CHARTS/SET_CONTENT',
}

export type HouseNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11 | 12;

export interface PlanetsData {
  name: PLANET_IDS;
  sign: SignTypes;
  dms30: number;
  dms360: number;
  house: HouseNumber;
}
export interface HousesData {
  house: HouseNumber;
  sign: SignTypes;
  dms30: number;
  dms360: number;
}

export interface State {
  planets: PlanetsData[];
  houses: HousesData[];
  planetsContent: NatalChartsPlanet[];
  housesContent: NatalChartsHouse[];
}

interface Set {
  type: typeof TYPES.SET;
  payload: {
    planets: PlanetsData[];
    houses: HousesData[];
  };
}

interface SetContent {
  type: typeof TYPES.SET_CONTENT;
  payload: {
    planetsContent: NatalChartsPlanet[];
    housesContent: NatalChartsHouse[];
  };
}

export type ActionTypes = Set | SetContent;
