import api from '..';

import { NatalChartsPlanet, NatalChartsHouse } from './interfaces';

const PLANETS = '/birthchart/planets';
const HOUSES = '/birthchart/houses';

export const getPlanetsContent = (): Promise<NatalChartsPlanet[]> => {
  return api.fetch(PLANETS, {
    method: 'GET',
  });
};

export const getHousesContents = (): Promise<NatalChartsHouse[]> => {
  return api.fetch(HOUSES, {
    method: 'GET',
  });
};
