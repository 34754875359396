export enum TYPES {
  SET_FLOW_STEP = 'ASTROLOGERS/MONETIZATION/SET_FLOW_STEP',
  SET_START_FLOW_CALLBACKS = 'ASTROLOGERS/MONETIZATION/SET_START_FLOW_CALLBACKS',
  SET_CURRENT_TRIGGER = 'ASTROLOGERS/MONETIZATION/SET_CURRENT_TRIGGER',
  SET_RENEW_DATA = 'ASTROLOGERS/MONETIZATION/SET_RENEW_DATA',
  SET_TRANSACTION_DATA = 'ASTROLOGERS/MONETIZATION/SET_TRANSACTION_DATA',
}

//TODO NEXT RELEASE
// https://gitlab.com/wowmaking/STRLG/astrostellium-app/-/commit/3fff3186391858e733fc938cf2833da91d0f07ee?page=2
export interface RenewData {
  currency: string;
  amount: number;
  productCode: string;
  priceText: string;
  minutesAmount: number;
}

interface StartFlowCallbacks {
  onSuccess?: (...args: any) => void;
  onSkip?: (...args: any) => void;
}

export interface State {
  flowStep: number;
  startFlowCallbacks: StartFlowCallbacks;
  currentTrigger: string;
  renewData: RenewData | null;
  transactionData: any | null;
}

interface SetFlowStep {
  type: typeof TYPES.SET_FLOW_STEP;
  payload: number;
}

interface SetStartFlowCallbacks {
  type: typeof TYPES.SET_START_FLOW_CALLBACKS;
  payload: StartFlowCallbacks;
}

interface SetCurrentTrigger {
  type: typeof TYPES.SET_CURRENT_TRIGGER;
  payload: string;
}

interface SetRenewData {
  type: typeof TYPES.SET_RENEW_DATA;
  payload: RenewData | null;
}

interface SetTransactionData {
  type: typeof TYPES.SET_TRANSACTION_DATA;
  payload: any | null;
}

export type ActionType = SetFlowStep | SetStartFlowCallbacks | SetCurrentTrigger | SetRenewData | SetTransactionData;
