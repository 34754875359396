import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import { getAvailableTime, setAwaitingNewTimeBalance, setLastTransactionDate } from 'store/astrologers/time/actions';
import { setTransactionData } from 'store/astrologers/monetization/actions';
import { checkOneTimePaymentStatus } from 'api/purchases';
import { getPrevProject } from 'store/billing/selectors';

import usePrevious from './use-previous';

const useChatTimeBalance = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAwaitingNewTimeBalance = useSelector((state: RootState) => state.astrologers.time.isAwaitingNewTimeBalance);
  const freeBalance = useSelector((state: RootState) => state.astrologers.time.balances?.freeBalance);
  const transactionData = useSelector((state: RootState) => state.astrologers.monetization.transactionData);
  const project = useSelector(getPrevProject);
  const prevFreeBalance = usePrevious(freeBalance);

  useEffect(() => {
    if (isAwaitingNewTimeBalance && !!transactionData?.order_id) {
      (async () => {
        try {
          const result = await checkOneTimePaymentStatus(transactionData.order_id, project);

          if (result === 'ok') {
            const time = await dispatch(getAvailableTime());
            dispatch(setLastTransactionDate(time.lastTransactionDate));
            dispatch(setTransactionData(null));

            Analytics.trackEvent('GetBalance', 'Success');
          }
        } catch (error) {
          Analytics.trackEvent('GetBalance', 'Fail');
          console.log('ERROR', error);
        } finally {
          dispatch(setAwaitingNewTimeBalance(false));
        }
      })();
    }
  }, [isAwaitingNewTimeBalance, project, transactionData]);

  useEffect(() => {
    if (prevFreeBalance && prevFreeBalance > 0 && freeBalance === 0) {
      Analytics.trackEvent('Astrologist', 'Sub_Benefits_Over');
    }
  }, [freeBalance, prevFreeBalance]);
};

export default useChatTimeBalance;
